import React from 'react';
import Table from '../../../../shared/design-system/ui/table';
import { columns } from './utils/columns';
import { data } from './utils/data';

const ProspectTable = () => {
  const x = 10;

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        backgroundColor: '#F9FAFB',
        padding: '24px',
        display: 'flex',
      }}
    >
      <Table columns={columns} data={data} />
    </div>
  );
};

export default ProspectTable;
