import React from 'react';
import { withTranslation } from 'react-i18next';
import { IProps } from './types';
import DeleteAlertModal from '../../../../../../shared/components/delete-alert-modal';

const DeleteEmailAccountModal: React.FC<IProps> = ({
  show,
  onSubmit,
  onClose,
  isRequestPending,
  isDefaultEmailAccount,
  t,
}) => (
  <DeleteAlertModal
    show={show}
    title={t('labels.delete_email_accounts')}
    onClose={onClose}
    onSubmit={!isDefaultEmailAccount ? onSubmit : onClose}
    isSubmitLoading={isRequestPending}
    contents={[
      `${t('messages.delete_email_message_one')}${t(
        'messages.delete_email_message_two',
      )}${t('messages.delete_email_message_three')}`,
    ]}
  />
);

export default withTranslation()(DeleteEmailAccountModal);
