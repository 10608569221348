import { DateTime } from 'luxon';
import { OnboardingSteps } from '../../shared/types/user-setting';
import {
  PlanType,
  SubscriptionPlans,
} from '../../shared/utils/subscription-plans';
import SubscriptionStatus from '../settings/components/billing-subscription/enums/subscription-status';

export type UpgradeOnboardingStepPayload = {
  onboardingStep: OnboardingSteps;
};

export type SubscriptionDetails = {
  planId: number;
  planName: string;
  planAmount: string;
  planMode: number;
  slots: number;
  emailAccountQuota: number;
  userQuota: number;
  startAt: string;
  nextBillingAt: string;
  subscriptionId(pin): string;
  customerId: string;
  paymentActionRequired: boolean;
  paymentActionUrl: string;
  paymentActionType: string;
  planType: PlanType;
  planCode: SubscriptionPlans;
  planTransitionDetails: {
    current: number;
    from: number;
  };
  status: SubscriptionStatus;
  pauseEndsAt?: string;
  pausedAt?: string;
};

export enum NotificationType {
  SequencePaused = 'sequence-paused',
  SequencePausedBounce = 'sequence-paused-bounce',
  EmailAccountDisconnected = 'email-account-disconnected',
  SequenceBulkProspectClient = 'sequence-prospect-bulk-client',
  SequenceProspectExportForClient = 'sequence-prospect-export-client',
  UniboxConversationForClient = 'unibox-conversation-client',
  EmailAccountBulkConnectClient = 'email-account-bulk-connect-client',
  SequenceReportClient = 'sequence-report-client',
  ProspectVerifyReport = 'prospect-verify-report',
  WhitelabelDomainSet = 'whitelabel-domain-set',
  SequenceEmailExportClient = 'sequence-email-export-client',
  SequenceProspectImport = 'sequence-prospect-import',
  DnsImport = 'dnc-import',
  DnsExport = 'dnc-export',
  SequenceProspectVerify = 'sequence-prospect-verify',
}

export enum NotificationReason {
  EmailDisconneted = 'emailDisconnected',
  SequencePaused = 'sequencePaused',
  SequenceProspectExportForClient = 'sequenceProspectExportForClient',
  SequenceReportClient = 'sequenceReportClient',
  EmailAccountBulkConnectClient = 'emailAccountBulkConnectClient',
  UniboxConversationForClient = 'uniboxConversationForClient',
  SequenceBulkProspectClient = 'sequenceBulkProspectClient',
  ProspectVerifyReport = 'prospectVerify',
  WhitelabelDomainSet = 'whitelabelDomainSet',
  DnsImport = 'dnsImport',
  DnsExport = 'dnsExport',
  SequenceEmailExportClient = 'sequenceEmailExportClient',
  SequenceProspectImport = 'sequenceProspectImport',
  SequenceProspectVerify = 'prospectVerify',
}

export enum NotificationAction {
  Reconnect = 'reconnect',
  Resume = 'resume',
  Download = 'download',
  Login = 'login',
}

export type Notifications = {
  emailAccountId?: number;
  sequenceId?: number;
  notificationType: NotificationType;
  accountId: number;
  userId: number;
  _id: string;
  log: string;
  redirectUrl: string;
  timestamp: string;
  timezone: string;
  hasRead: boolean;
  emailAccountType: number;
  reportUrl: string;
};

export type CompareAuthTokenPayload = {
  authToken: string;
};

export type LogoConfig = {
  primary: string;
  secondary: string;
  tertiary: string;
  favicon?: string;
  showAgencyNameWithLogo: boolean;
};

export type AgencyConfig = {
  showColdEmailTips: boolean;
  showAddStepButton: boolean;
  showEmailContentGuideHelp: boolean;
  showFAQ: boolean;
  showYTVideoGuide: boolean;
  showInfoTips: boolean;
  showGettingStarted: boolean;
  showHelp: boolean;
  showFeatureRequest: boolean;
  showChatSupport: boolean;
  showScheduleADemo: boolean;
  showWhatsNew: boolean;
  showInAppNotification: boolean;
  showClientName: boolean;
  closeOnLogout: boolean;
  isDefault: boolean;
  showNameFieldsInInviteModal: boolean;
  colorPalette: Record<string, string>;
  logo: LogoConfig;
  agencyName: string;
  agencyId?: number;
  clientName: string;
  showTermOfServiceAndPrivacyPolicy: boolean;
  ltdExpiryDate: string;
  clientBaseUrl?: string;
  clientLogo?: string;
  icon?: string;
  favicon?: string;
  companyName?: string;
  isLocallyCached?: boolean;
};

type FeatureLimit = {
  ['EMAIL.SEND']: string;
  ['PROSPECT.ADD']: string;
  ['LEAD.REVEAL']: string;
  ['SEQUENCE.EMAIL_ACCOUNT']?: number;
};

export type GetPostLoadMetaResponse = {
  featureLimits: FeatureLimit;
  accountUsageQuotaRemaining: FeatureLimit;
  accountUsageQuotaResetDate: Date;
  celloAuthToken: string;
  leadFinderAccountUsageQuotaResetDate: Date;
};

export type UnreadEmailThreadsCountPayload = {
  search?: string;
  categoryId?: number;
  sequenceIds?: number[];
  emailAccountIds?: number[];
  clientIds?: number[];
  owners?: number[];
  date?: {
    startDate?: DateTime | string;
    endDate?: DateTime | string;
  };
  shouldCalculateFilterCount?: boolean;
};
