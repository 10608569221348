import React from 'react';
import { AlertTriangle } from '@saleshandy/icons';
import Alert from '../../../../../shared/design-system/ui/alert';

const ZeroLimitErrorNudge = ({
  zeroLimitError,
  isBulkUpdatingEmailAccounts,
  maxLimit,
  t,
}) => {
  if (zeroLimitError && !isBulkUpdatingEmailAccounts) {
    return (
      <div className="row mt-2">
        <div className="col-md-10">
          <Alert
            variant="warning"
            description={<span>{t('messages.zero_limit_error')}</span>}
            icon={AlertTriangle}
          />
        </div>
      </div>
    );
  }

  if (zeroLimitError && isBulkUpdatingEmailAccounts) {
    return (
      <div className="bulk-update-email-error-wrapper mt-2">
        <div className="icon">
          <Alert
            variant="warning"
            icon={AlertTriangle}
            description={
              <span>
                Sending limit should be min 1 and max {maxLimit || 4000}
              </span>
            }
          />
        </div>
      </div>
    );
  }

  return <></>;
};

export default ZeroLimitErrorNudge;
