import React, { CSSProperties, useState } from 'react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

import Modal from '../modal';
import { LayoutColumns } from '@saleshandy/icons';
import { accessibleOnClick } from '../../../utils/accessible-on-click';

// These are the important styles to make sticky column pinning work!
// Apply styles like this using your CSS strategy of choice with this kind of logic to head cells, data cells, footer cells, etc.
// View the index.css file for more needed styles such as border-collapse: separate
const getCommonPinningStyles = (column, isSelected = false): CSSProperties => {
  const isPinned = column.getIsPinned();
  const isLastLeftPinnedColumn =
    isPinned === 'left' && column.getIsLastColumn('left');
  const isFirstRightPinnedColumn =
    isPinned === 'right' && column.getIsFirstColumn('right');

  let boxShadow;

  if (isLastLeftPinnedColumn) {
    boxShadow = '-4px 0 4px -4px gray inset';
  } else if (isFirstRightPinnedColumn) {
    boxShadow = '4px 0 4px -4px gray inset';
  }

  return {
    boxShadow,
    left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
    right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
    opacity: isPinned ? 0.95 : 1,
    position: isPinned ? 'sticky' : 'relative',
    width: column.getSize(),
    zIndex: isPinned ? 1 : 0,
    background: isSelected ? '#eff6ff' : 'white',
  };
};

const RawTable = ({ columns, data: rows }) => {
  const [showToggleColumnsModal, setShowToggleColumnsModal] = useState(false);

  const [data, setData] = useState(() => [...rows]);
  const [columnVisibility, setColumnVisibility] = useState({});

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
      columnPinning: {
        left: ['selection', 'firstName'],
      },
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
    enableColumnPinning: true,
    enableSorting: true,
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    defaultColumn: {
      // size: 200, // starting column size
      minSize: 20, // enforced during column resizing
      maxSize: 500, // enforced during column resizing
    },
  });

  return (
    <div className="shd-table">
      <table
        {...{
          style: {
            // width: '100%',
            width: table.getTotalSize(),
          },
        }}
      >
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => (
                <th
                  {...{
                    key: header.id,
                    colSpan: header.colSpan,
                    style: {
                      width: header.getSize(),
                      ...getCommonPinningStyles(header.column),
                    },
                  }}
                >
                  {index === 0 && (
                    <div className="column-checkbox">
                      <input
                        type="checkbox"
                        // indeterminate={table.getIsSomePageRowsSelected()}
                        checked={table.getIsAllRowsSelected()}
                        onChange={table.getToggleAllRowsSelectedHandler()}
                      />
                    </div>
                  )}
                  <div className="column-name">
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </div>
                  <div
                    {...{
                      onDoubleClick: () => header.column.resetSize(),
                      onMouseDown: header.getResizeHandler(),
                      onTouchStart: header.getResizeHandler(),
                      className: `resizer ${
                        table.options.columnResizeDirection
                      } ${header.column.getIsResizing() ? 'isResizing' : ''}`,
                      style: {
                        transform: '',
                      },
                    }}
                  />
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              style={{
                background: row.getIsSelected() ? '#eff6ff' : 'white',
                // color: row.getIsSelected() ? 'white' : 'black',
              }}
            >
              {row.getVisibleCells().map((cell, index) => (
                <td
                  key={cell.id}
                  style={{
                    width: cell.column.getSize(),
                    ...getCommonPinningStyles(cell.column, row.getIsSelected()),
                  }}
                >
                  {index === 0 && (
                    <div className="column-checkbox">
                      <input
                        type="checkbox"
                        checked={row.getIsSelected()}
                        onChange={row.getToggleSelectedHandler()}
                      />
                    </div>
                  )}
                  <div className="column-value">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      <div
        className="layout-columns-button"
        {...accessibleOnClick(() => {
          setShowToggleColumnsModal(true);
        })}
      >
        <LayoutColumns />
      </div>

      {/* Toggle Column Modal */}
      <Modal
        show={showToggleColumnsModal}
        title="Edit Table Column"
        className="edit-table-column"
        onHide={() => {
          setShowToggleColumnsModal(false);
        }}
        primaryBtnClickedHandler={() => {
          setShowToggleColumnsModal(false);
        }}
        secondaryBtnClickedHandler={() => {
          setShowToggleColumnsModal(false);
        }}
      >
        <div className="">
          <div>
            <label htmlFor="toggle-all">
              <input
                id="toggle-all"
                {...{
                  type: 'checkbox',
                  checked: table.getIsAllColumnsVisible(),
                  onChange: table.getToggleAllColumnsVisibilityHandler(),
                }}
              />{' '}
              Toggle All
            </label>
          </div>
          {table.getAllLeafColumns().map((column) => (
            <div key={column.id}>
              <label htmlFor={column.id}>
                <input
                  id={column.id}
                  {...{
                    type: 'checkbox',
                    checked: column.getIsVisible(),
                    onChange: column.getToggleVisibilityHandler(),
                  }}
                />{' '}
                {column.columnDef.header}
              </label>
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default RawTable;
