import { ResponseSuccess } from '../../../../../../../shared/types';
import api from '../../../../../../../api';
import { SendTestEmailPayload } from '../types/request-payload';
import { SUBJECT } from '../enums';

export const sendTestEmail = async ({
  fromEmailAccountId,
  to,
  content,
  sequenceId,
  subject,
  stepNumber,
  preheader,
  attachmentIds,
}: SendTestEmailPayload) => {
  let url = `/templates/send-test-mail`;

  if (!subject || subject === SUBJECT.EMPTY) {
    subject = SUBJECT.DEFAULT;
  }

  const payload: SendTestEmailPayload = {
    fromEmailAccountId,
    to,
    content,
    subject,
    preheader,
    attachmentIds,
  };

  if (sequenceId) {
    payload.stepNumber = stepNumber;
    url = `/sequences/${sequenceId}/test-email`;
  }

  return api.post<ResponseSuccess>(url, payload);
};
