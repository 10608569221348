import React, { useEffect } from 'react';
import { Send } from '@saleshandy/icons';
import AddToSequenceModalDropDown from '../../../../../../../shared/components/add-to-sequence-modal';
import { RequestStatus } from '../../../../../../../shared/enums/request-status';
import { showGeneralErrorNotification } from '../../../../../../../shared/utils/errors';
// eslint-disable-next-line import/no-cycle
import { IProps } from './add-to-sequence-modal-container';

const AddToSequenceModal: React.FC<IProps> = ({
  show,
  onHide,
  resetStepAndSequence,
  sendGetSequenceList,
  sendGetSequenceStepList,
  getSequenceListRequestStatus,
  getStepListRequestStatus,
  getSequenceListRequestError,
  getStepListRequestError,
  sequenceList,
  stepList,
  addToSequence,
  addToStepRequestStatus,
  sequence,
  isSubscribed,
}) => {
  useEffect(() => {
    if (sequence && sequence.id) {
      sendGetSequenceStepList(sequence.id);
    } else {
      sendGetSequenceList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => () => {
      resetStepAndSequence();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const sendGetStepRequest = (selectedSequence) => {
    sendGetSequenceStepList(selectedSequence);
  };

  useEffect(() => {
    if (getSequenceListRequestStatus === RequestStatus.Failed) {
      if (getSequenceListRequestError) {
        showGeneralErrorNotification(getSequenceListRequestError.message);
      }
    }
    if (getStepListRequestStatus === RequestStatus.Failed) {
      if (getStepListRequestError) {
        showGeneralErrorNotification(getStepListRequestError.message);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSequenceListRequestStatus, getStepListRequestStatus]);

  const onSubmit = (selectedSequenceId, selectedStepId) => {
    addToSequence(selectedSequenceId, selectedStepId);
    onHide();
  };

  const isFetchingSequenceList =
    getSequenceListRequestStatus === RequestStatus.Pending;
  const isFetchingStepList = getStepListRequestStatus === RequestStatus.Pending;

  const isSubmitRequestPending =
    addToStepRequestStatus === RequestStatus.Pending;
  return (
    <AddToSequenceModalDropDown
      show={show}
      contactSequenceList={sequenceList}
      contactStepList={stepList}
      isFetchingSequenceList={isFetchingSequenceList}
      isFetchingStepList={isFetchingStepList}
      isSubmitRequestPending={isSubmitRequestPending}
      onHide={onHide}
      onSubmit={onSubmit}
      sendGetStepRequest={sendGetStepRequest}
      className="add-sequence-prospect-modal"
      icon={<Send />}
      sequence={sequence}
      showWarning={!isSubscribed}
      submitRequestStatus={addToStepRequestStatus}
    />
  );
};

export default AddToSequenceModal;
