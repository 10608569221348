import React, { useState, useEffect } from 'react';
import { Button, ImageIcon } from '@saleshandy/design-system';
import type { IProps } from './logo-branding-container';
import Input from '../../../../../../shared/design-system/components/input';
import WhitelabelImagePicker from './components/whitelabel-image-picker';
import WhitelabelSkeletonView from './utils/whitelabel-skeleton-view';
import {
  executeOnRequestStatus,
  getIsRequestPending,
} from '../../../../../../shared/utils';
import { ResourceType } from '../../utils/enums';
import { Images } from '../../../../../../shared/app-constants';
import toaster, { Theme } from '../../../../../../shared/toaster';
import hasPermission from '../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../shared/utils/access-control/enums/permissions';
import planPermissionError from '../../../../../../shared/utils/errors/plan-permission-error';
import { getCapitalTitle } from '../../../../../../shared/utils/get-capital-title';

const LogoBranding: React.FC<IProps> = ({
  isLoading,
  resources,

  saveCompanyNameRequestStatus,
  getResourceRequestStatus,
  saveResourceRequestStatus,
  editCompanyNameRequestStatus,
  deleteResourceRequestStatus,
  saveCompanyNameRequestMessage,
  editCompanyNameRequestMessage,
  uploadResourceRequestStatus,
  editResourceRequestStatus,

  getResource,
  saveCompanyNameRequest,
  saveResourceRequest,
  editCompanyNameRequest,
  editResourceRequest,
  deleteResourceRequest,
  resetResourceState,
}) => {
  //* States */
  const [companyName, setCompanyName] = useState<string>(resources.companyName);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [logoImage, setLogoImage] = useState<File | null>(null);
  const [iconLogoImage, setIconLogoImage] = useState<File | null>(null);
  const [faviconImage, setFaviconImage] = useState<File | null>(null);
  const [actionCard, setActionCard] = useState<string>('Logo');

  //* Helper FUnctions */
  const handleInputChange = (v: string) => {
    setIsDirty(!!v.length);
    setCompanyName(v);
  };

  const showUpgradePlanErrorModal = () => {
    // setIsRestrictionModalVisible(true);
    const title = 'Upgrade your plan for whitelabeling';
    const content = [
      "Your current plan doesn't allow you to rebrand yourself.",
      'Please upgrade to the outreach scale or higher plans to use Whitelabel as a feature.',
    ];

    planPermissionError.showModal(title, content, true);
  };

  const saveCompanyName = async () => {
    if (!hasPermission(Permissions.WHITELABEL_ACCESS)) {
      showUpgradePlanErrorModal();
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    resources.companyName
      ? await editCompanyNameRequest(companyName)
      : await saveCompanyNameRequest(companyName);
  };

  const uploadResource = async (resource: File, resourceType: ResourceType) => {
    if (!hasPermission(Permissions.WHITELABEL_ACCESS)) {
      showUpgradePlanErrorModal();
      return;
    }

    setActionCard(getCapitalTitle(resourceType));
    await saveResourceRequest(resource, resourceType);
  };

  const editResource = async (resource: File, resourceType: ResourceType) => {
    if (!hasPermission(Permissions.WHITELABEL_ACCESS)) {
      showUpgradePlanErrorModal();
      return;
    }

    setActionCard(getCapitalTitle(resourceType));
    await editResourceRequest(resource, resourceType);
  };

  //* useEffect */
  useEffect(() => {
    if (hasPermission(Permissions.WHITELABEL_ACCESS)) {
      getResource();
    }

    return () => {
      resetResourceState();
    };
  }, []);

  useEffect(() => {
    executeOnRequestStatus({
      status: getResourceRequestStatus,
      onSuccess: () => {
        const name =
          resources.companyName?.length > 50
            ? `${resources.companyName.substring(0, 50)}...`
            : resources.companyName;

        setCompanyName(name);
      },
    });
  }, [getResourceRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: saveCompanyNameRequestStatus,
      onSuccess: () => {
        toaster.success(saveCompanyNameRequestMessage, { theme: Theme.New });
        resetResourceState();
      },
    });
  }, [saveCompanyNameRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: editCompanyNameRequestStatus,
      onSuccess: () => {
        toaster.success(editCompanyNameRequestMessage, { theme: Theme.New });
      },
    });
  }, [editCompanyNameRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: uploadResourceRequestStatus,
      onSuccess: () => {
        getResource();
        toaster.success(`${actionCard} saved successfully`, {
          theme: Theme.New,
        });
        if (actionCard !== 'Logo') {
          window.location.reload();
        }
      },
    });
  }, [uploadResourceRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: editResourceRequestStatus,
      onSuccess: () => {
        getResource();
        toaster.success(`${actionCard} saved successfully`, {
          theme: Theme.New,
        });
        if (actionCard !== 'Logo') {
          window.location.reload();
        }
      },
    });
  }, [editResourceRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: deleteResourceRequestStatus,
      onSuccess: () => {
        getResource();
        toaster.success(`${actionCard} deleted successfully`, {
          theme: Theme.New,
        });
        window.location.reload();
      },
    });
  }, [deleteResourceRequestStatus]);

  if (isLoading) {
    return <WhitelabelSkeletonView />;
  }

  return (
    <div className="d-flex logo-container">
      <div className="whitelabel__container">
        <div className="d-flex align-items-start gap-20">
          <div className="d-flex flex-column card-view">
            <div className="whitelabel__card">
              <span className="regular-2 font-medium popover-arrow-color-txt">
                Company Name
              </span>
              <div className="d-flex bs-mt-12 whitelabel__card--input-container">
                <Input
                  value={companyName}
                  placeholder="Enter company name"
                  onChange={(v) => handleInputChange(v)}
                />
                <Button
                  onClick={saveCompanyName}
                  disabled={!companyName || !isDirty}
                  className="bs-ml-12"
                  isLoading={
                    getIsRequestPending(saveCompanyNameRequestStatus) ||
                    getIsRequestPending(editCompanyNameRequestStatus)
                  }
                >
                  Save
                </Button>
              </div>
            </div>
            <div className="whitelabel__card mt-4">
              <WhitelabelImagePicker
                label="Logo"
                description="Set the tone for your brand with a login screen logo."
                dndDescription="Drag & Drop your logo here to upload"
                selectedImage={logoImage}
                addedImage={resources.logo}
                onSelecting={(file) => setLogoImage(file)}
                onCancel={() => setLogoImage(null)}
                onSave={(f: File) => uploadResource(f, ResourceType.Logo)}
                onEdit={(f: File) => editResource(f, ResourceType.Logo)}
                isLoading={
                  getIsRequestPending(saveResourceRequestStatus) ||
                  getIsRequestPending(editResourceRequestStatus)
                }
                deleteResourceRequestStatus={deleteResourceRequestStatus}
                onImageDelete={() => {
                  setActionCard(getCapitalTitle(ResourceType.Logo));
                  deleteResourceRequest(ResourceType.Logo);
                }}
                showImageCropper={true}
                cropperWidth={300}
                cropperHeight={100}
              />
            </div>
          </div>
          <div>
            <ImageIcon src={Images.LogoCompanyName} alt="image" />
          </div>
        </div>

        <div className="d-flex align-items-start gap-20">
          <div className="d-flex flex-column card-view">
            <div className="card-view">
              <div className="whitelabel__card mt-4">
                <WhitelabelImagePicker
                  label="Icon Logo"
                  description="Showcase your brand identity throughout the platform."
                  dndDescription="Drag & Drop your icon logo here to upload"
                  selectedImage={iconLogoImage}
                  addedImage={resources.icon}
                  onSelecting={(file) => setIconLogoImage(file)}
                  onCancel={() => setIconLogoImage(null)}
                  onSave={(f: File) => uploadResource(f, ResourceType.Icon)}
                  onEdit={(f: File) => editResource(f, ResourceType.Icon)}
                  isLoading={
                    getIsRequestPending(saveResourceRequestStatus) ||
                    getIsRequestPending(editResourceRequestStatus)
                  }
                  deleteResourceRequestStatus={deleteResourceRequestStatus}
                  onImageDelete={() => {
                    setActionCard(getCapitalTitle(ResourceType.Icon));
                    deleteResourceRequest(ResourceType.Icon);
                  }}
                  showImageCropper={true}
                  cropperWidth={126}
                  cropperHeight={126}
                />
              </div>
            </div>
            <div className="card-view">
              <div className="whitelabel__card mt-4 mb-2">
                <WhitelabelImagePicker
                  label="Favicon"
                  description="Make your brand instantly recognizable in browser tabs."
                  dndDescription="Drag & Drop your favicon here to upload"
                  selectedImage={faviconImage}
                  addedImage={resources.favicon}
                  onSelecting={(file) => setFaviconImage(file)}
                  onCancel={() => setFaviconImage(null)}
                  onSave={(f: File) => uploadResource(f, ResourceType.Favicon)}
                  onEdit={(f: File) => editResource(f, ResourceType.Favicon)}
                  isLoading={
                    getIsRequestPending(saveResourceRequestStatus) ||
                    getIsRequestPending(editResourceRequestStatus)
                  }
                  deleteResourceRequestStatus={deleteResourceRequestStatus}
                  onImageDelete={() => {
                    setActionCard(getCapitalTitle(ResourceType.Favicon));
                    deleteResourceRequest(ResourceType.Favicon);
                  }}
                  showImageCropper={true}
                  cropperWidth={126}
                  cropperHeight={126}
                />
              </div>
            </div>
          </div>

          <div className="mt-4">
            <ImageIcon src={Images.WhitelabelFavicon} alt="image" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoBranding;
