import React, { useEffect, useState } from 'react';

import type { IProps } from './delete-account-modal-container';
import { DeleteAccountModalFlow } from './enums';

import Modal from '../../../../../../shared/design-system/components/atoms/modal';
import toaster, { Theme } from '../../../../../../shared/toaster';

import {
  executeOnRequestStatus,
  executeOnErrorWithErrorCheck,
  AuthHelper,
} from '../../../../../../shared/utils';
import DeleteAccount from './delete-email-modal-flow/delete-account';
import ResetPassword from '../change-email-modal/change-email-modal-flow/reset-password';

const DeleteAccountModal: React.FC<IProps> = ({
  show,
  onClose,
  email,

  sendDeleteMyProfileRequest,
  resetDeleteMyProfileRequest,
  deleteMyProfileRequestStatus,
  deleteMyProfileRequestError,
  deleteMyProfileRequestMessage,

  sendResetPasswordRequest,
  resetPasswordRequestStatus,
}) => {
  const [modalState, setModalState] = useState<DeleteAccountModalFlow>(
    DeleteAccountModalFlow.DeleteAccount,
  );

  const onModalStateChange = (state: DeleteAccountModalFlow) => {
    setModalState(state);
  };

  const handleOnCancel = () => {
    onClose();
    setModalState(DeleteAccountModalFlow.DeleteAccount);
  };

  const handleResetPasswordSubmit = (value: string) => {
    sendResetPasswordRequest(value);
  };

  useEffect(() => {
    executeOnRequestStatus({
      status: deleteMyProfileRequestStatus,
      onSuccess: () => {
        toaster.success(deleteMyProfileRequestMessage, { theme: Theme.New });
        AuthHelper.logout({ redirect: true, pathName: '/signup' });
        resetDeleteMyProfileRequest();
      },
      onFailed: () => {
        executeOnErrorWithErrorCheck({
          error: deleteMyProfileRequestError,
          onError: () => {
            if (deleteMyProfileRequestError.code !== 1001) {
              toaster.error(deleteMyProfileRequestError.message, {
                theme: Theme.New,
              });
              resetDeleteMyProfileRequest();
            }
          },
        });
      },
    });
  }, [deleteMyProfileRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: resetPasswordRequestStatus,
      onSuccess: () => {
        handleOnCancel();
      },
    });
  }, [resetPasswordRequestStatus]);

  return (
    <>
      {modalState === DeleteAccountModalFlow.DeleteAccount && (
        <DeleteAccount
          className="change-email-modal"
          show={show}
          email={email}
          onCancel={onClose}
          onModalStateChange={onModalStateChange}
          sendDeleteMyProfileRequest={sendDeleteMyProfileRequest}
          deleteMyProfileRequestStatus={deleteMyProfileRequestStatus}
          deleteMyProfileRequestError={deleteMyProfileRequestError}
          resetDeleteMyProfileRequest={resetDeleteMyProfileRequest}
        />
      )}

      {modalState === DeleteAccountModalFlow.ResetPassword && (
        <Modal
          show={show}
          onClose={onClose}
          hideHeader
          hideFooter
          backdrop="static"
          className="change-email-modal"
        >
          <ResetPassword
            onCancel={handleOnCancel}
            onSubmit={handleResetPasswordSubmit}
            resetPasswordRequestStatus={resetPasswordRequestStatus}
          />
        </Modal>
      )}
    </>
  );
};

export default DeleteAccountModal;
