/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useMemo, useState, useEffect } from 'react';
import { map } from 'lodash';
import { withTranslation } from 'react-i18next';
import Modal from '../../../../../../../shared/design-system/components/atoms/modal';
import SHTag from '../../../../../../../shared/components/images/sh-tag';
import TagAutoSuggest from '../../../../../../../shared/design-system/components/molecules/tag-autosuggest';

import {
  AddTagsToProspectsPayload,
  RemoveTagsFromProspectsPayload,
} from '../../../../../types';
import type { IProps } from './tags-modal-container';
import { RenderMode } from '../../../../../../../shared/design-system/components/molecules/tag-autosuggest/tag-autosuggest';
import { getBulkActionRequestPayload } from '../../../../../helpers/helper';

const TagsModal: React.FC<IProps> = ({
  show,
  onHide,
  selectedProspects,
  setResetSelected,
  tags,
  addTagsToProspectsRequest,
  removeTagsFromProspectsRequest,
  selectedAllProspects,
  sendAddTagsToBulkProspectsRequest,
  sendRemoveTagsFromBulkProspectsRequest,
  sendGetTagsRequest,
  t,
}) => {
  const [tagsToRemove, setTagsToRemove] = useState([]);
  const [tagsToAdd, setTagsToAdd] = useState([]);

  /**
   * Map of tags to selected prospects.
   */
  const tagsToProspects = useMemo(() => {
    const _tagsToProspects = {};

    selectedProspects.forEach((prospect) => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { tags } = prospect;
      if (tags && tags.length) {
        tags.forEach(({ id }) => {
          if (
            _tagsToProspects[id] &&
            !_tagsToProspects[id].includes(prospect.id)
          ) {
            _tagsToProspects[id].push(prospect.id);
          } else {
            _tagsToProspects[id] = [prospect.id];
          }
        });
      }
    });

    return _tagsToProspects;
  }, [selectedProspects]);

  const addTagsToProspects = () => {
    const payload: AddTagsToProspectsPayload = {
      prospects: map(selectedProspects, 'id'),
      ids: [],
      newTags: [],
    };

    tagsToAdd.forEach((tag) => {
      if (tag.isNew) {
        payload.newTags.push(tag.name);
      } else {
        payload.ids.push(tag.id);
      }
    });

    if (selectedAllProspects?.isAllProspectsSelected) {
      const bulkActionPayload = {
        ...getBulkActionRequestPayload(selectedAllProspects),
        ids: payload.ids,
        newTags: payload.newTags,
      };

      sendAddTagsToBulkProspectsRequest(bulkActionPayload);
    } else {
      addTagsToProspectsRequest(payload);
    }
  };

  const removeTagsFromProspects = () => {
    const payload: RemoveTagsFromProspectsPayload = [];
    const tagIds: number[] = [];

    tagsToRemove.forEach((id) => {
      tagIds.push(id);
      payload.push({
        tagId: id,
        prospects: tagsToProspects[id],
      });
    });

    if (selectedAllProspects?.isAllProspectsSelected) {
      const bulkActionPayload = {
        ...getBulkActionRequestPayload(selectedAllProspects),
        tagIds,
      };

      sendRemoveTagsFromBulkProspectsRequest(bulkActionPayload);
    } else {
      removeTagsFromProspectsRequest(payload);
    }
  };

  const onDone = () => {
    if (tagsToAdd.length || tagsToRemove.length) {
      tagsToAdd.length && addTagsToProspects();
      tagsToRemove.length && removeTagsFromProspects();
      setResetSelected && setResetSelected(true);
    }
    onHide();
  };

  useEffect(() => {
    if (show) {
      sendGetTagsRequest();
    }
  }, []);

  return (
    <Modal
      show={show}
      className="tag-modal"
      titleContent={
        <div className="d-flex">
          <SHTag />
          <div className="pl-2"> {t('messages.tags_title')}</div>
        </div>
      }
      onClose={onHide}
      onSubmit={onDone}
      showCloseIcon={true}
      submitButtonText={t('labels.done')}
      backdrop="static"
      extraModalProps={{
        centered: true,
      }}
    >
      <div className="overflow-hidden">
        <TagAutoSuggest
          selectedProspects={selectedProspects}
          tags={tags}
          tagsToRemove={tagsToRemove}
          setTagsToRemove={setTagsToRemove}
          tagsToAdd={tagsToAdd}
          setTagsToAdd={setTagsToAdd}
          renderMode={RenderMode.Block}
          selectedAllProspectsDetails={selectedAllProspects}
        />
      </div>
    </Modal>
  );
};

export default withTranslation()(TagsModal);
