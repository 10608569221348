import React from 'react';
import ValidationChecklist from '../validation-checklist';
import passwordValidator from '../../utils/password-validator';

type IProps = {
  password: string;
  isDirty?: boolean;
};

const PasswordValidationChecklist: React.FC<IProps> = ({
  password,
  isDirty,
}) => {
  const isPasswordValid =
    passwordValidator.uppercase(password) &&
    passwordValidator.lowercase(password) &&
    passwordValidator.numeric(password) &&
    password.length >= 8 &&
    password.length <= 64;

  let message = '8 characters minimum';
  if (password.length > 8) {
    message = 'Less Than 64 Character';
  }

  return (
    <>
      <ValidationChecklist
        isDirty={isDirty}
        columns={[
          [
            {
              valid: passwordValidator.uppercase(password),
              text: 'One uppercase character',
            },
            {
              valid: passwordValidator.lowercase(password),
              text: 'One lowercase character',
            },
          ],
          [
            {
              valid: passwordValidator.numeric(password),
              text: 'One numerical character',
            },
            {
              valid: isPasswordValid,
              text: message,
            },
          ],
        ]}
      />
    </>
  );
};

export default PasswordValidationChecklist;
