import { EmailInboxFilters } from '../../type';
import { UnifiedInboxFeed } from '../../enum/unified-inbox.enum';

export const getDefaultFilters = (): EmailInboxFilters => ({
  page: 1,
  limit: 10,

  feed: UnifiedInboxFeed.All,
  search: '',

  outcomes: null,
  owners: [],
  sequences: [],
  emailAccounts: [],
  clients: [],
  date: null,
});
