import * as _ from 'lodash';
import { PaginationLocalStorageKey } from '../../../shared/design-system/components/organisms/table/types';
import { RequestStatus } from '../../../shared/enums/request-status';
import toaster from '../../../shared/toaster';
import { ResponseErrorWithHandled } from '../../../shared/types';
import { executeOnRequestStatus } from '../../../shared/utils/execute-on-request-status';
import {
  getIsRequestPending,
  getIsRequestSucceeded,
} from '../../../shared/utils/get-request-status';
import { BulkActionProspect } from '../enums/prospect';
import { constants } from '../../../shared/enums/constants';
import { isRequestPending } from '../../settings/components/billing-subscription/utils/helper';
import { ProspectsFilters } from '../../../shared/types/prospects-filters';
import { SelectAllContacts } from '../types';

// * Show Hide Loading Bar Handler
export const showHideLoadingBar = (
  status: RequestStatus,
  showLoading,
  hideLoading,
  error: ResponseErrorWithHandled,
  showGeneralErrorNotification: {
    (message: React.ReactNode): void;
    (message: React.ReactNode): void;
    (arg0: any): void;
  },
) => {
  if (status === RequestStatus.Pending) {
    showLoading();
  }
  if (status === RequestStatus.Succeeded) {
    hideLoading();
  }
  if (status === RequestStatus.Failed) {
    hideLoading();
    if (error) {
      showGeneralErrorNotification(error.message);
    }
  }
};

// * View Email Verification And Tag Modal Handler
export const viewEmailVerificationAndTagModal = (
  selectedProspects,
  showProspectSelectedRowInfoBanner,
  selectedAllProspects: SelectAllContacts,
  totalProspect: number,
) => {
  if (
    selectedProspects.length > 0 ||
    (selectedAllProspects?.isAllProspectsSelected &&
      selectedAllProspects?.deSelectedContactIds?.length !== totalProspect)
  ) {
    return true;
  }

  showProspectSelectedRowInfoBanner();

  return false;
};

// * Get Admin Settings Request Handler
export const getAdminSettingsRequest = (
  adminSettings,
  sendGetAdminSettingsRequest,
) => {
  if (adminSettings.length < 1) {
    sendGetAdminSettingsRequest();
  }
};

export const getProspectTablePerPageSize = () => {
  const prospectRowsPerPage = localStorage.getItem(
    PaginationLocalStorageKey.ProspectRowsPerPage,
  );

  if (prospectRowsPerPage) {
    return Number(prospectRowsPerPage);
  }

  return constants.PROSPECT_PAGE_SIZE;
};

// * Update Prospect List Requests Handler
export const updateProspectListRequestsHandler = ({
  data,
  calledFrom,
  requestOptions,
  selectedProspects,
  prospectPaginationData,
  resetTags,
  sendGetTagsRequest,
  resetSelectedContacts,
  sendGetProspectRequest,
  resetSequenceAndStepData,
  changeProspectPagination,
  sendGetProspectCountRequest,
  resetBulkRequests,
  search,
  resetPageNumber = false,
}) => {
  let { pageNum } = prospectPaginationData;

  if (calledFrom === BulkActionProspect.RemoveProspect) {
    if (pageNum !== 0 && selectedProspects.length === data.length) {
      pageNum -= 1;
    }
    changeProspectPagination({ ...prospectPaginationData, pageNum });
    resetSelectedContacts();
    resetSequenceAndStepData();
  }
  if (calledFrom === BulkActionProspect.TagsUpdated) {
    resetSelectedContacts();
    resetTags();
  }
  if (requestOptions.sendGetProspectRequest) {
    sendGetProspectRequest({
      pageNum: resetPageNumber ? 0 : pageNum,
      pageSize: prospectPaginationData?.pageSize,
      search,
    });
  }
  if (requestOptions.sendGetProspectCountRequest) {
    sendGetProspectCountRequest({ search });
  }
  if (requestOptions.sendGetTagsRequest) {
    sendGetTagsRequest();
  }
  resetBulkRequests();
};

// * Send Get Details Of Contact Request Handler
export const sendGetDetailsOfContactRequestHandler = ({
  id,
  setSelectedContactId,
  setProspectDetails,
  viewShowProspectDetailsModal,
}) => {
  if (id) {
    setSelectedContactId(id);
    setProspectDetails(id);
    viewShowProspectDetailsModal();
  }
};

// * Verify Prospects Handler
export const verifyProspectsHandler = ({
  setRemainingCredit,
  selectedProspects,
  getEmailVerificationCreditsResponse,
  hideEmailVerificationModal,
  viewPurchaseCreditsModal,
  sendEmailVerificationFn,
}) => {
  if (
    selectedProspects.length >
    getEmailVerificationCreditsResponse?.creditsAvailable
  ) {
    setRemainingCredit(
      selectedProspects.length -
        getEmailVerificationCreditsResponse?.creditsAvailable,
    );
    hideEmailVerificationModal();
    viewPurchaseCreditsModal();
  } else {
    sendEmailVerificationFn();
  }
};

// * Get Handle 3d Secure Card Response Handler
export const handle3dSecureCardResponse = ({
  isError,
  serverResponse,
  sendGetEmailVerificationCreditsRequest,
  resetPurchaseEmailVerificationCreditsResponse,
  sendEmailVerificationFn,
  hidePurchaseCreditsModal,
}) => {
  if (isError) {
    toaster.error(
      'Payment failed! Please retry again after some time from billing section.',
    );
  } else {
    toaster.success(serverResponse.payload.message);
    sendGetEmailVerificationCreditsRequest();
    resetPurchaseEmailVerificationCreditsResponse();
    sendEmailVerificationFn();
    hidePurchaseCreditsModal();
  }
};

// * Add and Remove Tags From Prospects Request Success Handler
export const addRemoveTagsFromProspectRequestSuccessHandler = ({
  updateProspectListRequests,
  t,
}) => {
  updateProspectListRequests(
    {
      sendGetProspectCountRequest: false,
      sendGetProspectRequest: true,
      sendGetTagsRequest: false,
    },
    BulkActionProspect.TagsUpdated,
  );
  toaster.success(t('messages.prospects_updated'));
};

/* Success Handler for bulk tags add remove operation */
export const addRemoveTagsInBulkFromProspectRequestSuccessHandler = ({
  updateProspectListRequests,
  t,
}) => {
  updateProspectListRequests(
    {
      sendGetProspectCountRequest: false,
      sendGetProspectRequest: true,
      sendGetTagsRequest: false,
    },
    BulkActionProspect.TagsUpdated,
  );
};

// * Get Available EV Credits And EV Plan Handler
export const getAvailableEvCreditsAndEvPlanHandler = ({
  sendGetEmailVerificationCreditsRequest,
  getEmailVerificationPlanResponse,
  sendGetEmailVerificationPlansRequest,
}) => {
  sendGetEmailVerificationCreditsRequest();
  if (!getEmailVerificationPlanResponse) {
    // sendGetEmailVerificationPlansRequest();
  }
};

// * Refresh Selected Contacts Handler
export const refreshSelectedContactHandler = ({
  selectedContactId,
  showProspectDetailsModal,
  getProspectRequestStatus,
  sendGetSingleProspectDetailsRequest,
}) => {
  if (showProspectDetailsModal) {
    executeOnRequestStatus({
      status: getProspectRequestStatus,
      onSuccess: () => {
        sendGetSingleProspectDetailsRequest(selectedContactId);
      },
    });
  }
};

// * Execute With Value Check Handler
export const executeWithValueCheck = ({ value, handler }) => {
  if (value) {
    handler();
  }
};

// * Get Purchase EV Credits Request Success Handler
export const purchaseEvCreditsRequestSuccessHandler = ({
  showPurchaseCreditModel,
  sendPurchaseEmailVerificationCreditsRequestMessage,
  getPurchaseEmailVerificationCreditsResponse,
  handle3dSecureCard,
  sendEmailVerificationFn,
  sendGetEmailVerificationCreditsRequest,
  hidePurchaseCreditsModal,
  resetPurchaseEmailVerificationCreditsResponse,
}) => {
  if (
    sendPurchaseEmailVerificationCreditsRequestMessage &&
    showPurchaseCreditModel
  ) {
    if (getPurchaseEmailVerificationCreditsResponse.requires_action) {
      handle3dSecureCard(getPurchaseEmailVerificationCreditsResponse);
    } else {
      toaster.success(sendPurchaseEmailVerificationCreditsRequestMessage);
      sendEmailVerificationFn();
      sendGetEmailVerificationCreditsRequest();
      hidePurchaseCreditsModal();
      resetPurchaseEmailVerificationCreditsResponse();
    }
  }
};

// * Get Purchase EV Credits Request Error Handler
export const purchaseEvCreditsRequestErrorHandler = ({
  sendPurchaseEmailVerificationCreditsRequestMessage,
  hidePurchaseCreditsModal,
  resetPurchaseEmailVerificationCreditsResponse,
}) => {
  if (sendPurchaseEmailVerificationCreditsRequestMessage) {
    toaster.error(sendPurchaseEmailVerificationCreditsRequestMessage);
    hidePurchaseCreditsModal();
    resetPurchaseEmailVerificationCreditsResponse();
  }
};

// * Get Should Return Null Handler
export const shouldReturnNull = ({
  getProspectRequestStatus,
  prospects,
  prospectCount,
  search,
}) =>
  !getIsRequestSucceeded(getProspectRequestStatus) &&
  prospects.length === 0 &&
  prospectCount === 0 &&
  search === null;

// * Get Should Show Selected Row Info Banner Handler
export const getShouldShowSelectedRowInfoBanner = ({
  selectedProspects,
  selectedAllProspects,
  isProspectSelectedRowInfoBannerVisible,
}) =>
  selectedProspects.length > 0 ||
  selectedAllProspects?.isAllProspectsSelected ||
  isProspectSelectedRowInfoBannerVisible;

// * Get Tags Filter Applied Handler
export const getTagsFilterApplied = ({ prospectsFilters }) =>
  !!(prospectsFilters.tags && prospectsFilters.tags.length);

// * Get Is Purchase Email Verification Credits Loading State Handler
export const getIsPurchaseEmailVerificationCreditsLoadingState = ({
  sendPurchaseEmailVerificationCreditsRequestStatus,
  sendGetEmailVerificationPlansRequestStatus,
  is3dSecureResponseStatePending,
}) =>
  getIsRequestPending(sendPurchaseEmailVerificationCreditsRequestStatus) ||
  getIsRequestPending(sendGetEmailVerificationPlansRequestStatus) ||
  is3dSecureResponseStatePending;

// Select All Prospects Limit
export const SELECT_ALL_PRODUCTS_LIMIT = 25000;

export const getBulkActionRequestPayload = (selectedAllProspects) => {
  const { deSelectedContactIds, contactsFilter } = selectedAllProspects;

  return {
    ...(deSelectedContactIds.length > 0 && { deSelectedContactIds }),
    ...(!_.isEmpty(contactsFilter) && { contactsFilter }),
  };
};

export const isAnyBulkActionPending = (
  sendVerifyBulkProspectsRequestStatus,
  sendDeleteBulkProspectsRequestStatus,
  sendUnsubscribeBulkProspectsRequestStatus,
  sendAddBulkProspectsToStepsRequestStatus,
  sendAddTagsToBulkProspectsRequestStatus,
  sendRemoveTagsFromBulkProspectsRequestStatus,
) =>
  isRequestPending(sendVerifyBulkProspectsRequestStatus) ||
  isRequestPending(sendDeleteBulkProspectsRequestStatus) ||
  isRequestPending(sendUnsubscribeBulkProspectsRequestStatus) ||
  isRequestPending(sendAddBulkProspectsToStepsRequestStatus) ||
  isRequestPending(sendAddTagsToBulkProspectsRequestStatus) ||
  isRequestPending(sendRemoveTagsFromBulkProspectsRequestStatus);

export const isAnyFilterApplied = (
  prospectsFilters: ProspectsFilters,
): boolean => {
  const { tags, owners, sequences, status } = prospectsFilters;
  const verificationStatus = prospectsFilters['verification status'];
  const createdDate = prospectsFilters['created date'];
  const creationSource = prospectsFilters['creation source'] || [];

  return (
    tags.length > 0 ||
    owners.length > 0 ||
    sequences.length > 0 ||
    status.length > 0 ||
    verificationStatus.length > 0 ||
    createdDate.length > 0 ||
    (creationSource && creationSource.length > 0)
  );
};
