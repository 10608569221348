/* eslint-disable react/react-in-jsx-scope */
import {
  Like,
  Calendar,
  Dislike,
  Clock,
  Mail,
  CircleX,
  Tag,
  OutOfOffice,
  UserStar,
} from '@saleshandy/icons';
import isNull from 'lodash/isNull';
import { ReactNode } from 'react';
import {
  ActivityData,
  EngagementActivityType,
  Icon,
} from '../../../../../../../../../../shared/design-system/components/molecules/activity-column/type';
import { getFormattedTime } from '../../../../../../../../../../shared/utils';
import {
  ContactStatusFilterEnum,
  ContactStatusType,
  ContactStatusTypeDisplayName,
  ContactTotal,
  ProspectOutcome,
  ProspectOutcomeDisplayName,
  SequenceProspectStatus,
  StatusColor,
} from '../../../../../../../../enums';
import { StatusFilterDetail } from '../../../../../../../../types';
import i18n from '../../../../../../../../../../i18n';
import { Images } from '../../../../../../../../../../shared/app-constants';
import { OutcomeSentimentIconColor } from '../../../../../../../../../settings/enums/custom-outcome';

const getColor = (status: string) => {
  switch (status) {
    case ContactStatusFilterEnum.Total:
      return StatusColor.COLOR_TOTAL;
    case ContactStatusFilterEnum.Active:
      return StatusColor.COLOR_ACTIVE;
    case ContactStatusFilterEnum.Finished:
      return StatusColor.COLOR_FINISH;
    case ContactStatusFilterEnum.Open:
      return StatusColor.COLOR_OPEN;
    case ContactStatusFilterEnum.Clicked:
      return StatusColor.COLOR_CLICKED;
    case ContactStatusFilterEnum.Replied:
      return StatusColor.COLOR_REPLIED;
    case ContactStatusFilterEnum.Bounced:
      return StatusColor.COLOR_BOUNCED;
    case ContactStatusFilterEnum.Unsubscribed:
      return StatusColor.COLOR_UNSUBSCRIBED;
    default:
      return StatusColor.COLOR_TOTAL;
  }
};

export const stepFilterMapper = (statusFilterList) => {
  const segments = [
    {
      text: ContactStatusTypeDisplayName.clicked,
      type: ContactStatusFilterEnum.Clicked,
      count: 0,
    },
    {
      text: ContactStatusTypeDisplayName.SentNotOpen,
      type: ContactStatusFilterEnum.SentNotOpen,
      count: 0,
    },
    {
      text: ContactStatusTypeDisplayName.OpenNotClick,
      type: ContactStatusFilterEnum.OpenNotClick,
      count: 0,
    },
    {
      text: ContactStatusTypeDisplayName.OpenNoReply,
      type: ContactStatusFilterEnum.OpenNotReply,
      count: 0,
    },
    {
      text: ContactStatusTypeDisplayName.ClickedNoReply,
      type: ContactStatusFilterEnum.ClickedNoReply,
      count: 0,
    },
    {
      text: ContactStatusTypeDisplayName.unsubscribed,
      type: ContactStatusFilterEnum.Unsubscribed,
      count: 0,
    },
  ];

  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const status in statusFilterList) {
    // eslint-disable-next-line no-plusplus
    for (let j = 0; j < segments.length; j++) {
      if (status === segments[j].type) {
        segments[j].count = Number(statusFilterList[status]);
        break;
      }
    }
  }

  const statusFilter: StatusFilterDetail[] = [
    {
      text: 'Total',
      type: ContactTotal.TOTAL,
      count: statusFilterList.total,
      countColor: getColor(ContactTotal.TOTAL),
    },
    {
      text: ContactStatusTypeDisplayName.NotContacted,
      type: ContactStatusFilterEnum.NotContacted,
      count: 0,
      countColor: getColor(ContactStatusFilterEnum.Active),
    },
    {
      text: ContactStatusTypeDisplayName.Contacted,
      type: ContactStatusFilterEnum.Contacted,
      count: 0,
      countColor: getColor(ContactStatusFilterEnum.Active),
    },
    {
      text: ContactStatusTypeDisplayName[ContactStatusFilterEnum.Open],
      type: ContactStatusFilterEnum.Open,
      count: 0,
      countColor: getColor(ContactStatusFilterEnum.Open),
    },
    {
      text: ContactStatusTypeDisplayName[ContactStatusFilterEnum.Replied],
      type: ContactStatusFilterEnum.Replied,
      count: 0,
      countColor: getColor(ContactStatusFilterEnum.Replied),
    },
    {
      text: ContactStatusTypeDisplayName[ContactStatusFilterEnum.Clicked],
      type: ContactStatusFilterEnum.Clicked,
      count: 0,
      countColor: getColor(ContactStatusFilterEnum.Clicked),
      isDropDownSegment: true,
      segmentOptions: segments,
    },
  ];

  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const status in statusFilterList) {
    // eslint-disable-next-line no-plusplus
    for (let j = 0; j < statusFilter.length; j++) {
      if (status === statusFilter[j].type) {
        statusFilter[j].count = Number(statusFilterList[status]);
        break;
      }
    }
  }

  return statusFilter;
};

export const prospectOutcomeMapper = (outcomeFilterList, allOutcomes) => {
  let customOutcomes =
    allOutcomes?.filter((outcome) => !outcome.isDefault) || [];
  customOutcomes = customOutcomes.map((outcome) => {
    const outcomeObj = {
      text: outcome.name,
      type: outcome.name,
      count: 0,
      isCustomOutcome: true,
      sentiment: outcome.sentiment,
    };
    return outcomeObj;
  });
  const outcomes = [
    {
      text: ProspectOutcomeDisplayName.OutOfOffice,
      type: ProspectOutcome.OutOfOffice,
      count: 0,
    },
    {
      text: ProspectOutcomeDisplayName.NotInterested,
      type: ProspectOutcome.NotInterested,
      count: 0,
    },
    {
      text: ProspectOutcomeDisplayName.NotNow,
      type: ProspectOutcome.NotNow,
      count: 0,
    },
    {
      text: ProspectOutcomeDisplayName.DoNotContact,
      type: ProspectOutcome.DoNotContact,
      count: 0,
    },
    {
      text: ProspectOutcomeDisplayName.Uncategorized,
      type: ProspectOutcome.Uncategorized,
      count: 0,
    },
  ];

  if (customOutcomes.length) {
    outcomes.push(...customOutcomes);
  }

  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const status in outcomeFilterList) {
    // eslint-disable-next-line no-plusplus
    for (let j = 0; j < outcomes.length; j++) {
      if (status === outcomes[j].type) {
        outcomes[j].count = Number(outcomeFilterList[status]);
        break;
      }
    }
  }

  const outcomeFilter: StatusFilterDetail[] = [
    {
      text: ProspectOutcomeDisplayName.Interested,
      type: ProspectOutcome.Interested,
      count: 0,
      dealValue: 0,
      countColor: getColor(ContactTotal.TOTAL),
    },
    {
      text: ProspectOutcomeDisplayName.MeetingBooked,
      type: ProspectOutcome.MeetingBooked,
      count: 0,
      dealValue: 0,
      countColor: getColor(ContactTotal.TOTAL),
    },
    {
      text: ProspectOutcomeDisplayName.Closed,
      type: ProspectOutcome.Closed,
      count: 0,
      dealValue: 0,
      countColor: getColor(ContactTotal.TOTAL),
    },
    {
      text: ProspectOutcomeDisplayName.OutOfOffice,
      type: ProspectOutcome.OutOfOffice,
      count: 0,
      countColor: getColor(ContactStatusFilterEnum.Replied),
      isDropDownSegment: true,
      segmentOptions: outcomes,
    },
  ];

  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const status in outcomeFilterList) {
    // eslint-disable-next-line no-plusplus
    for (let j = 0; j < outcomeFilter.length; j++) {
      if (status === outcomeFilter[j].type) {
        outcomeFilter[j].count = Number(outcomeFilterList[status]);
        outcomeFilter[j].dealValue = outcomeFilterList[`${status}DealValue`];
        break;
      }
    }
  }

  return outcomeFilter;
};

export const mapSequenceStatusToContactFilter = (
  sequenceStatus: SequenceProspectStatus,
): ContactStatusType => {
  switch (sequenceStatus) {
    case SequenceProspectStatus.Active:
      return ContactStatusType.Active;
    case SequenceProspectStatus.Paused:
      return ContactStatusType.Paused;
    case SequenceProspectStatus.Unsubscribed:
      return ContactStatusType.Unsubscribed;
    case SequenceProspectStatus.Replied:
      return ContactStatusType.Replied;
    case SequenceProspectStatus.Finished:
      return ContactStatusType.Finished;
    case SequenceProspectStatus.BlackListed:
      return ContactStatusType.Blacklisted;
    case SequenceProspectStatus.Bounced:
      return ContactStatusType.Bounced;
    default:
      return ContactStatusType.Active;
  }
};

export const contactListMapper = (contactList, timeZone, allOutcomes) =>
  contactList.map(
    ({
      id,
      attributes,
      sequenceProspects,
      verificationStatus,
      isSubscribed,
      bounceType,
      tags,
    }) => {
      const name = attributes.map(
        ({ attributeValue: value, fieldRef: { isDefault, label } }) => ({
          value,
          isDefault,
          label,
        }),
      );
      name.verificationStatus = verificationStatus;

      const sequenceProspect = sequenceProspects?.[0] || {};
      const { status } = sequenceProspect;
      const { categoryId, currentStep = {} } = sequenceProspect;
      const { number: stepNumber, id: stepId, type, sequence } = currentStep;

      const {
        updatedAt,
        currentStepClickedAt,
        currentStepOpenedAt,
        currentStepCompletedAt,
        currentStepRepliedAt,
      } = sequenceProspect;

      const history: ActivityData[] = [
        {
          timeZone,
          date: currentStepCompletedAt,
          text: i18n.t('messages.email_sent_on'),
          iconLabel: Icon.MAIL,
          type: EngagementActivityType.EmailSent,
          stepType: type,
        },
        {
          timeZone,
          date: currentStepOpenedAt,
          text: i18n.t('messages.email_opened_on'),
          iconLabel: Icon.EYE,
          type: EngagementActivityType.EmailOpened,
          stepType: type,
        },
        {
          timeZone,
          date: currentStepClickedAt,
          text: i18n.t('messages.link_clicked_on'),
          iconLabel: Icon.LINK,
          type: EngagementActivityType.LinkClicked,
          stepType: type,
        },
        {
          timeZone,
          date: currentStepRepliedAt,
          text: i18n.t('messages.email_replied_on'),
          iconLabel: Icon.REPLY,
          type: EngagementActivityType.EmailReplied,
          stepType: type,
        },
      ];

      const lastActivity = updatedAt;

      return {
        id,
        name,
        stepNumber: `${i18n.t('labels.step')} #${stepNumber}`,
        stepType: type,
        status: mapSequenceStatusToContactFilter(status),
        stepId,
        history,
        lastActivity: lastActivity
          ? getFormattedTime(lastActivity?.toString())
          : '',
        isSubscribed,
        isBounced: !isNull(bounceType),
        tags,
        outcome: {
          prospectId: id,
          sequenceId: sequence?.id,
          outcome: categoryId
            ? {
                categoryId,
              }
            : '',
          allOutcomes: allOutcomes || [],
        },
      };
    },
  );

export const sequenceStepFilterMapper = (currentSequenceStep, stepFilterList) =>
  currentSequenceStep.reduce((acc, current) => {
    const stepDetail = stepFilterList.stepNumberWithCount.find(
      (item) => item.stepId === current.id,
    );
    if (stepDetail) {
      return [
        ...acc,
        {
          id: stepDetail.stepId,
          count: stepDetail.count,
          text: `Step ${stepDetail.stepNumber}`,
          number: stepDetail.stepNumber,
        },
      ];
    }

    return [
      ...acc,
      {
        id: current.id,
        count: 0,
        number: current.number,
        text: `Step ${current.number}`,
      },
    ];
  }, []);

export const getOutcomeIcon = (
  outcome: ProspectOutcomeDisplayName | string,
  isColorsIcon = false,
  defaultProps = null,
): ReactNode => {
  if (defaultProps && defaultProps.sentiment && !defaultProps.isDefault) {
    return (
      <UserStar
        height={defaultProps?.height || 18}
        width={defaultProps?.width || 18}
        color={
          isColorsIcon
            ? OutcomeSentimentIconColor[defaultProps.sentiment]
            : 'gray-txt-15'
        }
        style={{
          color: isColorsIcon
            ? OutcomeSentimentIconColor[defaultProps.sentiment]
            : '#6b7280',
        }}
      />
    );
  }

  if (outcome === 'All Outcomes') {
    return <Tag className="gray-txt-11" />;
  }

  switch (outcome) {
    case ProspectOutcomeDisplayName.Interested:
      return (
        <Like
          height={defaultProps?.height || 18}
          width={defaultProps?.width || 18}
          className={isColorsIcon ? 'purple-txt-15' : 'gray-txt-15'}
        />
      );
    case ProspectOutcomeDisplayName.MeetingBooked:
      return (
        <Calendar
          height={defaultProps?.height || 18}
          width={defaultProps?.width || 18}
          className={isColorsIcon ? 'blue-txt-23' : 'gray-txt-15'}
        />
      );
    case ProspectOutcomeDisplayName.Closed:
      return (
        <img
          src={isColorsIcon ? Images.BxDollarGreen : Images.BxDollarGray}
          alt="mail-send"
          height={defaultProps?.height || 18}
          width={defaultProps?.width || 18}
        />
      );
    case ProspectOutcomeDisplayName.OutOfOffice:
      return (
        <OutOfOffice
          height={defaultProps?.height || 18}
          width={defaultProps?.width || 18}
          className={isColorsIcon ? 'green-txt-24' : 'gray-txt-15'}
        />
      );
    case ProspectOutcomeDisplayName.NotInterested:
      return (
        <Dislike
          height={defaultProps?.height || 18}
          width={defaultProps?.width || 18}
          className={isColorsIcon ? 'orange-txt-17' : 'gray-txt-15'}
        />
      );
    case ProspectOutcomeDisplayName.NotNow:
      return (
        <Clock
          height={defaultProps?.height || 18}
          width={defaultProps?.width || 18}
          className={isColorsIcon ? 'red-txt-15' : 'gray-txt-15'}
        />
      );
    case ProspectOutcomeDisplayName.DoNotContact:
      return (
        <CircleX
          height={defaultProps?.height || 18}
          width={defaultProps?.width || 18}
          className={isColorsIcon ? 'red-txt-15' : 'gray-txt-15'}
        />
      );
    default:
      return (
        <Mail
          height={defaultProps?.height || 18}
          width={defaultProps?.width || 18}
          className={`${isColorsIcon ? 'purple-txt-15' : 'gray-txt-15'}  `}
        />
      );
  }
};
