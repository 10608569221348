import React from 'react';
import {
  SearchInput as ShSearchInput,
  SearchInputProps,
} from '@saleshandy/design-system';

const SearchInput: React.FC<SearchInputProps> = ({ ...rest }) => (
  <ShSearchInput {...rest} />
);

export default SearchInput;
