import React from 'react';
import ConfirmationModal from '../../../../../../shared/design-system/components/atoms/confirmation-modal';
import { IProps } from './types';
import DeleteAlertModal from '../../../../../../shared/components/delete-alert-modal';

const DeleteSchedule: React.FC<IProps> = ({
  show,
  onSubmit,
  onClose,
  isRequestPending,
}) => (
  <DeleteAlertModal
    show={show}
    onClose={onClose}
    onSubmit={onSubmit}
    isSubmitLoading={isRequestPending}
    title="Do you want to delete this schedule?"
    contents={[
      'If you delete the schedule, the sequences connected with this schedule will get affected.',
    ]}
  />
);

export default DeleteSchedule;
