import { createAsyncThunk } from '@reduxjs/toolkit';
import { ResponseErrorWithHandled, ResponseSuccess } from '../../shared/types';
import {
  bulkCategoryAssign,
  bulkDelete,
  bulkMarkAsReadUnread,
  deleteEmailConversation,
  forwardEmail,
  getEmailAccountsListForFilter,
  getEmailCategories,
  getEmailInbox,
  getEmailThread,
  getEmailThreadActivity,
  getSequencesListForFilter,
  replyEmail,
  updateEmailCategory,
  updateEmailReadUnreadStatus,
  contactStatus,
  bulkContactStatus,
} from './helpers/unified-inbox.api';
import {
  BulkCategoryAssignPayload,
  BulkDeletePayload,
  BulkMarkAsReadUnreadPayload,
  BulkContactStatusPayload,
  ContactStatusPayload,
  EmailCategoryPayload,
  EmailDeletePayload,
  EmailInboxFiltersPayload,
  EmailReadUnreadStatusPayload,
  EmailThreadActivityPayload,
  EmailThreadFilters,
  ForwardEmailPayload,
  ReplyEmailPayload,
} from './type';

export const getEmailInboxRequest = createAsyncThunk<
  ResponseSuccess,
  EmailInboxFiltersPayload,
  { rejectValue: ResponseErrorWithHandled }
>('unifiedInbox/getEmailInbox', async (args, thunkAPI) => {
  try {
    return await getEmailInbox(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getEmailThreadRequest = createAsyncThunk<
  ResponseSuccess,
  EmailThreadFilters,
  { rejectValue: ResponseErrorWithHandled }
>('unifiedInbox/getEmailThread', async (args, thunkAPI) => {
  try {
    return await getEmailThread(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getEmailThreadActivityRequest = createAsyncThunk<
  ResponseSuccess,
  EmailThreadActivityPayload,
  { rejectValue: ResponseErrorWithHandled }
>('unifiedInbox/getEmailThreadActivity', async (args, thunkAPI) => {
  try {
    return await getEmailThreadActivity(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getSequencesListForFilterRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('unifiedInbox/getSequencesListForFilter', async (_args, thunkAPI) => {
  try {
    return await getSequencesListForFilter();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getEmailAccountsListForFilterRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('unifiedInbox/getEmailAccountsListForFilter', async (_args, thunkAPI) => {
  try {
    return await getEmailAccountsListForFilter();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const replyEmailRequest = createAsyncThunk<
  ResponseSuccess,
  ReplyEmailPayload,
  { rejectValue: ResponseErrorWithHandled }
>('unifiedInbox/replyEmail', async (args, thunkAPI) => {
  try {
    return await replyEmail(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const forwardEmailRequest = createAsyncThunk<
  ResponseSuccess,
  ForwardEmailPayload,
  { rejectValue: ResponseErrorWithHandled }
>('unifiedInbox/forwardEmail', async (args, thunkAPI) => {
  try {
    return await forwardEmail(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getEmailCategoriesRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('unifiedInbox/getEmailCategories', async (_args, thunkAPI) => {
  try {
    return await getEmailCategories();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const updateEmailCategoryRequest = createAsyncThunk<
  ResponseSuccess,
  EmailCategoryPayload,
  { rejectValue: ResponseErrorWithHandled }
>('unifiedInbox/updateEmailCategory', async (args, thunkAPI) => {
  try {
    return await updateEmailCategory(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

const updateEmailReadUnreadStatusHandler = async (args, thunkAPI) => {
  try {
    return await updateEmailReadUnreadStatus(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
};

export const markEmailConversationAsRead = createAsyncThunk<
  ResponseSuccess,
  EmailReadUnreadStatusPayload,
  { rejectValue: ResponseErrorWithHandled }
>(
  'unifiedInbox/markEmailConversationAsRead',
  updateEmailReadUnreadStatusHandler,
);

export const markEmailConversationAsUnRead = createAsyncThunk<
  ResponseSuccess,
  EmailReadUnreadStatusPayload,
  { rejectValue: ResponseErrorWithHandled }
>(
  'unifiedInbox/markEmailConversationAsUnRead',
  updateEmailReadUnreadStatusHandler,
);

export const deleteEmailConversationRequest = createAsyncThunk<
  ResponseSuccess,
  EmailDeletePayload,
  { rejectValue: ResponseErrorWithHandled }
>('unifiedInbox/deleteEmailConversation', async (args, thunkAPI) => {
  try {
    return await deleteEmailConversation(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const contactStatusRequest = createAsyncThunk<
  ResponseSuccess,
  ContactStatusPayload,
  { rejectValue: ResponseErrorWithHandled }
>('unifiedInbox/status', async (args, thunkAPI) => {
  try {
    return await contactStatus(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const bulkContactStatusRequest = createAsyncThunk<
  ResponseSuccess,
  BulkContactStatusPayload,
  { rejectValue: ResponseErrorWithHandled }
>('unifiedInbox/bulk-status', async (args, thunkAPI) => {
  try {
    return await bulkContactStatus(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const bulkMarkAsReadUnreadRequest = createAsyncThunk<
  ResponseSuccess,
  BulkMarkAsReadUnreadPayload,
  { rejectValue: ResponseErrorWithHandled }
>('unifiedInbox/bulkMarkAsReadUnread', async (args, thunkAPI) => {
  try {
    return await bulkMarkAsReadUnread(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const bulkDeleteRequest = createAsyncThunk<
  ResponseSuccess,
  BulkDeletePayload,
  { rejectValue: ResponseErrorWithHandled }
>('unifiedInbox/bulkDelete', async (args, thunkAPI) => {
  try {
    return await bulkDelete(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const bulkCategoryAssignRequest = createAsyncThunk<
  ResponseSuccess,
  BulkCategoryAssignPayload,
  { rejectValue: ResponseErrorWithHandled }
>('unifiedInbox/bulkCategoryAssign', async (args, thunkAPI) => {
  try {
    return await bulkCategoryAssign(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
