import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Prospect } from '../types';
import ProspectTagsRow from '../../../../../shared/design-system/components/molecules/prospect-tags-row';
import {
  OverlayTooltip,
  Placement,
} from '../../../../../shared/design-system/components/overlay';
import Icon from '../../../../../shared/design-system/components/atoms/icon';
import ContactStatusTag from '../../../../../shared/design-system/components/atoms/contact-status-tag/contact-status-tag';

const columnHelper = createColumnHelper<Prospect>();

const iconStyle = { marginLeft: '.25rem', marginTop: '.15rem' };

const iconConfigs = {
  skip: {
    identifier: 'circle-check',
    toolTipText: 'Unverified',
    className: 'gray-txt-23',
  },
  valid: {
    identifier: 'circle-check',
    toolTipText: 'Valid',
    className: 'green-txt-14',
  },
  risky: {
    identifier: 'danger',
    toolTipText: 'Risky',
    className: 'orange-txt-6',
  },
  bad: {
    identifier: 'warning',
    toolTipText: 'Bad',
    className: 'red-txt-6',
  },
  unknown: {
    identifier: 'danger',
    toolTipText: 'Risky',
    className: 'red-txt-6',
  },
  inProgress: {
    identifier: 'rotate-clockwise',
    toolTipText: 'In Progress',
    className: 'gray-txt-23',
  },
};

// Name (firstName & lastName) - Email (email & verificationStatus) - Job Title - Status - Outcome - Tags - Last Contacted

export const columns = [
  columnHelper.accessor('name', {
    id: 'name',
    enableHiding: false,
    header: 'Name',
    cell: ({ renderValue }) => renderValue(),
    size: 228,
  }),
  columnHelper.accessor('email', {
    id: 'email',
    header: 'Email',
    size: 258,
    cell: ({ row, renderValue }) => {
      const iconConfig = iconConfigs[row.original.verificationStatus];

      const getVerificationIcon = () => (
        <Icon
          identifier={iconConfig?.identifier}
          className={iconConfig?.className}
          style={iconStyle}
        />
      );

      const verificationToolTip = (
        <OverlayTooltip
          placement={Placement.Bottom}
          text={iconConfig?.toolTipText}
        >
          {getVerificationIcon()}
        </OverlayTooltip>
      );

      return (
        <div className="d-flex align-items-center" style={{ gap: '4px' }}>
          <div style={{ width: '1rem', height: '1rem' }}>
            {verificationToolTip}
          </div>
          <div>{renderValue()}</div>
        </div>
      );
    },
  }),
  columnHelper.accessor('jobTitle', {
    id: 'jobTitle',
    header: 'Job Title',
    size: 152,
    cell: ({ renderValue }) => renderValue(),
  }),
  columnHelper.accessor('status', {
    id: 'status',
    size: 132,
    header: 'Status',
    cell: ({ renderValue }) => (
      <ContactStatusTag status={renderValue()} isSequenceProspectTag={true} />
    ),
  }),
  columnHelper.accessor('outcomes', {
    id: 'outcomes',
    header: 'Outcomes',
    size: 172,
    cell: () => '-',
  }),
  columnHelper.accessor('tags', {
    id: 'tags',
    header: 'Tags',
    size: 222,
    cell: ({ renderValue }) => <ProspectTagsRow tags={renderValue()} />,
  }),
  columnHelper.accessor('lastContactedAt', {
    id: 'lastContactedAt',
    header: 'Last Contacted At',
    size: 196,
    cell: ({ renderValue }) => renderValue(),
  }),
];
