import React, { useEffect, useState } from 'react';
import ConfirmationModal from '../../../../../../shared/design-system/components/atoms/confirmation-modal';
import { RequestStatus } from '../../../../../../shared/enums/request-status';
import toaster, { Theme } from '../../../../../../shared/toaster';
import AddToSequenceModal from '../modals/add-to-sequence-modal';
import type { IProps } from './prospect-details-container';
import ProspectDetailsModal from './prospect-details-modal';
import TagsModal from '../modals/tags-modal';
import hasResource from '../../../../../../shared/utils/access-control/has-resource';
import { ResourceIdentifiers } from '../../../../../../shared/utils/access-control/enums/resource-identifiers';

const ProspectDetails: React.FC<IProps> = ({
  show,
  onHide,
  selectedProspectId,
  resetSelectedProspectId,
  selectedProspectData,
  selectedProspectSequences,
  addToStepRequestError,
  addToStepRequestStatus,
  resetSequenceAndStepData,
  addToStepRequestFailedCount,
  addToStepRequestSuccessfulCount,
  sendAddContactToStepRequest,
  sendUnsubscribeProspectRequest,
  sendRemoveProspectRequest,
  sendSequenceMarkAsRepliedRequest,
  unsubscribeProspectStatus,
  unsubscribeProspectError,
  removeProspectError,
  removeProspectStatus,
  unsubscribeProspectMessage,
  removeProspectMessage,
  addToStepRequestMessage,
  sendGetSingleProspectSequencesDetailsRequest,
  getSingleProspectSequencesDetailsStatus,
  markAsRepliedError,
  markAsRepliedMessage,
  markAsRepliedStatus,
  sendSingleProspectRemoveFromSequencesRequest,
  removeFromSequenceError,
  removeFromSequenceMessage,
  removeFromSequenceStatus,
  saveContactAttributesRequestStatus,
  saveContactAttributesRequestMessage,
  saveContactAttributesRequestError,
  sendSaveContactAttributesRequest,
  sendGetSingleProspectDetailsRequest,
  getSingleProspectDetailsStatus,
  sendSequenceMarkAsFinishedRequest,
  markAsFinishedStatus,
  markAsFinishedMessage,
  markAsFinishedError,
  isTagsFilterApplied,
  resetRemoveProspectRequest,
  resetSaveContactAttributesRequestStatus,
  sendPrimaryEmailChangeInProspectRequest,
  sendDeleteEmailFromProspectRequest,
  sendDeletePhoneFromProspectRequest,
  deleteSecondaryEmailRequestStatus,
  resetProspectDeleteEmailRequestStatus,
  deleteSecondaryPhoneRequestStatus,
  resetProspectDeletePhoneRequestStatus,
  primaryEmailChangeRequestStatus,
  resetProspectPrimaryEmailChangeRequestStatus,
  allOutcomes,
}) => {
  const [showAddToSequenceModal, setShowAddToSequenceModal] = useState<boolean>(
    false,
  );

  const [showDeleteProspect, setShowDeleteProspectModal] = useState<boolean>(
    false,
  );

  const [showTagsModal, setShowTagsModal] = useState<boolean>(false);

  const hideAddToSequenceStepModal = () => {
    setShowAddToSequenceModal(false);
  };

  const handleAddToSequenceStepModal = () => {
    if (!selectedProspectData?.isSubscribed) {
      toaster.error(
        'It is not possible to add unsubscribed contacts to the sequence',
        { theme: Theme.New },
      );
    } else if (hasResource(ResourceIdentifiers.CONTACTS_IMPORT)) {
      setShowAddToSequenceModal(true);
    }
  };

  const hideDeleteProspectModal = () => {
    setShowDeleteProspectModal(false);
  };

  const showDeleteProspectModal = () => {
    setShowDeleteProspectModal(true);
  };

  const hideTagsModal = () => {
    /**
     * Handling a case in which a tag filter is applied and the same tag is deleted from the selected
     * Prospect.
     */
    isTagsFilterApplied && onHide();
    setShowTagsModal(false);
  };

  const viewTagsModal = () => {
    setShowTagsModal(true);
  };

  const addToSequenceRequest = (sequenceId, stepId) => {
    hasResource(ResourceIdentifiers.SEQUENCES_CONTACTS_IMPORT) &&
      sendAddContactToStepRequest({
        sequenceId,
        stepId,
        contactIds: [selectedProspectId],
      });
  };

  const unsubscribeProspectRequest = () => {
    sendUnsubscribeProspectRequest([selectedProspectId]);
  };

  const removeProspect = () => {
    showDeleteProspectModal();
  };

  const removeProspectRequest = () => {
    sendRemoveProspectRequest([selectedProspectId]);
  };

  const sendGetSingleProspectSequencesRequest = () => {
    sendGetSingleProspectSequencesDetailsRequest(selectedProspectId);
  };

  const prospectSequenceMarkAsReplied = (stepId: number) => {
    const contactAndStepIds = [];
    contactAndStepIds.push({
      contactId: selectedProspectId,
      stepId,
    });
    sendSequenceMarkAsRepliedRequest(contactAndStepIds);
  };

  const prospectSequenceMarkedAsFinished = (stepId: number) => {
    const contactAndStepIds = [{ contactId: selectedProspectId, stepId }];
    sendSequenceMarkAsFinishedRequest(contactAndStepIds);
  };

  const prospectSequenceRemoveFromSequence = (sequenceId: number) => {
    sendSingleProspectRemoveFromSequencesRequest({
      sequenceId,
      contactIds: [selectedProspectId],
    });
  };

  useEffect(() => {
    sendGetSingleProspectDetailsRequest(selectedProspectId);
  }, []);

  // Add to Step Request.
  useEffect(() => {
    if (addToStepRequestStatus === RequestStatus.Succeeded) {
      hideAddToSequenceStepModal();
      resetSequenceAndStepData();
      sendGetSingleProspectSequencesRequest();
      if (addToStepRequestSuccessfulCount > 0) {
        toaster.success(
          addToStepRequestMessage?.replace(/contact/gi, 'Prospect'),
        );
      }
      if (addToStepRequestFailedCount > 0) {
        toaster.error('Prospect already exists in the selected step', {
          theme: Theme.New,
        });
      }
    }
    if (
      addToStepRequestStatus === RequestStatus.Failed &&
      addToStepRequestError
    ) {
      resetSequenceAndStepData();
      toaster.error(
        addToStepRequestError.message?.replace(/contact/gi, 'Prospect'),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToStepRequestStatus]);

  // Unsubscribe Request.
  useEffect(() => {
    if (unsubscribeProspectStatus === RequestStatus.Succeeded) {
      toaster.success(
        unsubscribeProspectMessage?.replace(/contact/gi, 'Prospect'),
      );
      resetSequenceAndStepData();
    }
    if (
      unsubscribeProspectStatus === RequestStatus.Failed &&
      unsubscribeProspectError
    ) {
      toaster.error(
        unsubscribeProspectError.message?.replace(/contact/gi, 'Prospect'),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unsubscribeProspectStatus]);

  // remove Prospect Request.
  useEffect(() => {
    if (removeProspectStatus === RequestStatus.Succeeded) {
      onHide();
      toaster.success(removeProspectMessage?.replace(/contact/gi, 'Prospect'));
      resetSequenceAndStepData();
      resetSelectedProspectId();
    }
    if (removeProspectStatus === RequestStatus.Failed && removeProspectError) {
      toaster.error(
        removeProspectError.message?.replace(/contact/gi, 'Prospect'),
      );
      resetRemoveProspectRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeProspectStatus]);

  // Mark as replied for sequence.
  useEffect(() => {
    if (markAsRepliedStatus === RequestStatus.Succeeded) {
      toaster.success(markAsRepliedMessage?.replace(/contact/gi, 'Prospect'));
      sendGetSingleProspectSequencesRequest();
      resetSequenceAndStepData();
    }
    if (markAsRepliedStatus === RequestStatus.Failed && markAsRepliedError) {
      toaster.error(
        markAsRepliedError.message?.replace(/contact/gi, 'Prospect'),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markAsRepliedStatus]);

  // Mark as replied for sequence.
  useEffect(() => {
    if (markAsFinishedStatus === RequestStatus.Succeeded) {
      toaster.success(markAsFinishedMessage?.replace(/contact/gi, 'Prospect'));
      sendGetSingleProspectSequencesRequest();
      resetSequenceAndStepData();
    }
    if (markAsFinishedStatus === RequestStatus.Failed && markAsFinishedError) {
      toaster.error(
        markAsFinishedError.message?.replace(/contact/gi, 'Prospect'),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markAsFinishedStatus]);

  // Remove From sequence.
  useEffect(() => {
    if (removeFromSequenceStatus === RequestStatus.Succeeded) {
      toaster.success(
        removeFromSequenceMessage?.replace(/contact/gi, 'Prospect'),
      );
      sendGetSingleProspectSequencesRequest();
      resetSequenceAndStepData();
    }
    if (
      removeFromSequenceStatus === RequestStatus.Failed &&
      removeFromSequenceError
    ) {
      toaster.error(
        removeFromSequenceError.message?.replace(/contact/gi, 'Prospect'),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeFromSequenceStatus]);

  // Save Contact Attribute Request.
  useEffect(() => {
    if (saveContactAttributesRequestStatus === RequestStatus.Succeeded) {
      toaster.success(
        saveContactAttributesRequestMessage?.replace(/contact/gi, 'Prospect'),
      );
      resetSequenceAndStepData();
    }
    if (
      saveContactAttributesRequestStatus === RequestStatus.Failed &&
      saveContactAttributesRequestError
    ) {
      toaster.error(
        saveContactAttributesRequestError.message?.replace(
          /contact/gi,
          'Prospect',
        ),
      );
    }
    resetSaveContactAttributesRequestStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveContactAttributesRequestStatus]);

  const isRemoveProspectRequestPending =
    removeProspectStatus === RequestStatus.Pending;

  const contentRemoveProspectModal =
    'This will remove all the history of the prospect(s).';

  const titleRemoveProspectModal = 'Are you sure you want to delete?';

  return (
    <>
      {show && (
        <ProspectDetailsModal
          show={show}
          onHide={onHide}
          selectedProspectId={selectedProspectId}
          viewAddToSequenceModal={handleAddToSequenceStepModal}
          unsubscribeProspectRequest={unsubscribeProspectRequest}
          removeProspectRequest={removeProspect}
          selectedProspectData={selectedProspectData}
          sendGetSingleProspectSequencesDetailsRequest={
            sendGetSingleProspectSequencesRequest
          }
          getSingleProspectSequencesDetailsStatus={
            getSingleProspectSequencesDetailsStatus
          }
          selectedProspectSequences={selectedProspectSequences}
          prospectSequenceMarkAsReplied={prospectSequenceMarkAsReplied}
          prospectSequenceMarkAsFinished={prospectSequenceMarkedAsFinished}
          prospectSequenceRemoveFromSequence={
            prospectSequenceRemoveFromSequence
          }
          prospectSequenceAddToStep={addToSequenceRequest}
          sendSaveContactAttributesRequest={sendSaveContactAttributesRequest}
          sendGetSingleProspectDetailsRequest={
            sendGetSingleProspectDetailsRequest
          }
          getSingleProspectDetailsStatus={getSingleProspectDetailsStatus}
          saveContactAttributesRequestStatus={
            saveContactAttributesRequestStatus
          }
          isSingleProspectSubscribed={selectedProspectData?.isSubscribed}
          viewTagsModal={viewTagsModal}
          sendPrimaryEmailChangeInProspectRequest={
            sendPrimaryEmailChangeInProspectRequest
          }
          sendDeleteEmailFromProspectRequest={
            sendDeleteEmailFromProspectRequest
          }
          sendDeletePhoneFromProspectRequest={
            sendDeletePhoneFromProspectRequest
          }
          deleteSecondaryEmailRequestStatus={deleteSecondaryEmailRequestStatus}
          resetProspectDeleteEmailRequestStatus={
            resetProspectDeleteEmailRequestStatus
          }
          deleteSecondaryPhoneRequestStatus={deleteSecondaryPhoneRequestStatus}
          resetProspectDeletePhoneRequestStatus={
            resetProspectDeletePhoneRequestStatus
          }
          primaryEmailChangeRequestStatus={primaryEmailChangeRequestStatus}
          resetProspectPrimaryEmailChangeRequestStatus={
            resetProspectPrimaryEmailChangeRequestStatus
          }
          allOutcomes={allOutcomes}
        />
      )}

      {showAddToSequenceModal && (
        <AddToSequenceModal
          show={showAddToSequenceModal}
          onHide={hideAddToSequenceStepModal}
          addToSequence={addToSequenceRequest}
          isSubscribed={selectedProspectData?.isSubscribed}
        />
      )}

      {showDeleteProspect && (
        <ConfirmationModal
          show={showDeleteProspect}
          content={contentRemoveProspectModal}
          title={titleRemoveProspectModal}
          onClose={hideDeleteProspectModal}
          onSubmit={removeProspectRequest}
          isRequestPending={isRemoveProspectRequestPending}
          showSuccessButton={true}
          showCancelButton={true}
        />
      )}
      {showTagsModal && (
        <TagsModal
          onHide={hideTagsModal}
          show={showTagsModal}
          selectedProspects={[selectedProspectData]}
        />
      )}
    </>
  );
};

export default ProspectDetails;
