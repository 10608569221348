import React from 'react';
import { AlertCircle } from '@saleshandy/icons';
import Alert from '../../../ui/alert';

const WarningMessage = ({ message }) => (
  <Alert
    variant="alert"
    description={message}
    icon={AlertCircle}
    className="mt-2"
  />
);

export default WarningMessage;
