/* eslint-disable radix */
import api from '../../../api';
import { ResponseSuccess } from '../../../shared/types';
import { Source } from '../../../shared/enums/source';
import { ContactStatusType } from '../../sequence/enums';
import {
  ProspectParams,
  ProspectCountParams,
  AddToStepParams,
  markAsRepliedParam,
  AddTagsToProspectsPayload,
  RemoveTagsFromProspectsPayload,
  BulkActionPayload,
  changePrimaryEmailInProspectPayload,
  deleteSecondaryEmailParams,
  deleteSecondaryPhoneParams,
} from '../types';

// Prospect
export const getProspect = async (params: ProspectParams) =>
  api.get<ResponseSuccess>('/contacts', { params });

export const getProspectCount = async (params: ProspectCountParams) =>
  api.get<ResponseSuccess>('/contacts/count', { params });

export const getSequenceList = async () =>
  api.get<ResponseSuccess>('sequences/list?numberOfSteps=gt:0');

export const getStepList = async (sequenceId) =>
  api.get<ResponseSuccess>(`/sequences/${Number(sequenceId)}/steps`);

export const addContactsToStep = async ({
  contactIds,
  sequenceId,
  stepId,
}: AddToStepParams) =>
  api.post<ResponseSuccess>(`/sequences/${Number(sequenceId)}/contacts`, {
    stepId,
    contactIds,
  });

export const removeProspect = async (contactIds: number[]) =>
  api.patch<ResponseSuccess>(`/contacts`, {
    contactIds,
  });

export const unsubscribeProspect = async (contactIds: number[]) =>
  api.post<ResponseSuccess>('/contacts/unsubscribe', {
    contactIds,
  });

export const getSingleProspectDetails = async (contactId) =>
  api.get<ResponseSuccess>(`/contacts/${parseInt(contactId)}`);

export const getMinimalSingleProspectSequencesDetails = async (contactId) =>
  api.get<ResponseSuccess>(`/contacts/${parseInt(contactId)}/minimal/sequences`);

export const singleProspectSequencesMarkAsReplied = async (
  contactAndStepIds: markAsRepliedParam[],
) =>
  api.patch<ResponseSuccess>(`/contacts/status`, {
    contactAndStepIds,
    status: ContactStatusType.Replied,
  });

export const singleProspectSequencesMarkAsFinished = async (
  contactAndStepIds: markAsRepliedParam[],
) =>
  api.patch<ResponseSuccess>(`/contacts/status`, {
    contactAndStepIds,
    status: ContactStatusType.Finished,
  });

export const singleProspectRemoveFromSequences = async ({
  sequenceId,
  contactIds,
}) =>
  api.patch<ResponseSuccess>(`/sequences/${parseInt(sequenceId)}/contacts`, {
    contactIds,
  });

export const saveContactAttributes = async (contactId: number, attributes) =>
  api.post<ResponseSuccess>(`/contacts/${contactId}/profile`, {
    attributes,
  });

export const saveContactDealValue = async (sequenceId, attributes) =>
  api.patch<ResponseSuccess>(`/contacts/${parseInt(sequenceId)}/deal-value`, {
    attributes,
  });

export const addProspectManually = async (attributes) =>
  api.post<ResponseSuccess>(
    `/contacts/`,
    {
      attributes,
    },
    { headers: { 'sh-source': Source.ProspectManually } },
  );

export const verifyProspects = async ({ contactIds }) =>
  api.post<ResponseSuccess>('/contacts/verify', {
    contactIds,
  });

export const getTags = async () => api.get<ResponseSuccess>(`/tag`);

export const addTagsToProspects = async ({
  ids,
  prospects,
  newTags,
}: AddTagsToProspectsPayload) =>
  api.post<ResponseSuccess>('/tag/assign', {
    ids,
    prospects,
    newTags,
  });

export const removeTagsFromProspects = async (
  payload: RemoveTagsFromProspectsPayload,
) => api.post<ResponseSuccess>('/tag/un-assign', payload);

export const verifyBulkProspects = async (payload: BulkActionPayload) =>
  api.post<ResponseSuccess>('/contacts/bulk-verify', payload);

export const deleteBulkProspects = async (payload: BulkActionPayload) =>
  api.patch<ResponseSuccess>('/contacts/bulk-delete', payload);

export const unsubscribeBulkProspects = async (payload: BulkActionPayload) =>
  api.post<ResponseSuccess>('/contacts/bulk-unsubscribe', payload);

export const addBulkProspectsToStep = async (payload: BulkActionPayload) =>
  api.post<ResponseSuccess>(
    `/sequences/${parseInt(payload.sequenceId)}/bulk-contacts`,
    {
      stepId: payload.stepId,
      ...(payload?.deSelectedContactIds && {
        deSelectedContactIds: payload?.deSelectedContactIds,
      }),
      ...(payload?.contactsFilter && {
        contactsFilter: payload?.contactsFilter,
      }),
    },
  );

export const addTagsToBulkProspects = async (payload: BulkActionPayload) =>
  api.post<ResponseSuccess>('/tag/bulk-assign', payload);

export const removeTagsFromBulkProspects = async (payload: BulkActionPayload) =>
  api.post<ResponseSuccess>('/tag/bulk-un-assign', payload);

export const changePrimaryEmailInProspect = async ({
  primaryEmailProspectInfoId,
  contactId,
}: changePrimaryEmailInProspectPayload) =>
  api.post<ResponseSuccess>(`/contacts/${contactId}/change-primary-email`, {
    primaryEmailProspectInfoId: primaryEmailProspectInfoId,
  });

export const deleteSecondaryEmail = async ({
  prospectInfoId,
  contactId,
}: deleteSecondaryEmailParams) =>
  api.delete<ResponseSuccess>(`/contacts/${contactId}/email/${prospectInfoId}
    `);

export const deleteSecondaryPhone = async ({
  prospectInfoId,
  contactId,
}: deleteSecondaryPhoneParams) =>
  api.delete<ResponseSuccess>(`/contacts/${contactId}/phone/${prospectInfoId}
        `);
