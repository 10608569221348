import React from 'react';
import Input from '../input';

type SearchProps = {
  value: string;
  searchPlaceholder?: string;
  onChange: (value: string) => void;
  onKeyDown?: (
    value: string,
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => void;
  autoFocusSearch?: boolean;
};

const Search: React.FC<SearchProps> = ({
  value,
  onChange,
  onKeyDown,
  searchPlaceholder = 'Search',
  autoFocusSearch = false,
}) => (
  <div className="transfer-search">
    <Input
      placeholder={searchPlaceholder}
      icons={[
        {
          place: Input.IconPlace.Left,
          identifier: 'search',
        },
      ]}
      value={value}
      onChange={onChange}
      className="transfer-search-input"
      onKeyDown={(event) => onKeyDown?.(value, event)}
      autoFocus={autoFocusSearch}
    />
  </div>
);

export default React.memo(Search);
