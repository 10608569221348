import { ContactVerificationStatus } from '../../../../sequence/enums';
import { Prospect } from '../types';

export const data: Prospect[] = [
  {
    id: 1,
    name: 'Shoaib Sayyed',
    email: 'shoaib@saleshandy.com',
    verificationStatus: ContactVerificationStatus.Valid,
    jobTitle: 'Software Engineer',
    status: 'active',
    outcomes: [],
    tags: [
      {
        id: 1,
        name: 'Saleshandy',
      },
    ],
    lastContactedAt: '16 Sept, 2024 at 12:11 AM',
  },
  {
    id: 2,
    name: 'Rajat Chahar',
    email: 'rajat@saleshandy.com',
    verificationStatus: ContactVerificationStatus.Bad,
    jobTitle: 'Software Engineer',
    status: 'bounced',
    outcomes: [],
    tags: [
      {
        id: 1,
        name: 'Saleshandy',
      },
    ],
    lastContactedAt: '16 Sept, 2024 at 12:11 AM',
  },
  {
    id: 3,
    name: 'Dhruvil Gajjar',
    email: 'dhruvil@saleshandy.com',
    verificationStatus: ContactVerificationStatus.Unverified,
    jobTitle: 'Software Engineer',
    status: 'unsubscribed',
    outcomes: [],
    tags: [
      {
        id: 1,
        name: 'Saleshandy',
      },
    ],
    lastContactedAt: '16 Sept, 2024 at 12:11 AM',
  },
  {
    id: 4,
    name: 'Pramod Vekariya',
    email: 'pramod@saleshandy.com',
    verificationStatus: ContactVerificationStatus.Risky,
    jobTitle: 'Software Engineer',
    status: 'active',
    outcomes: [],
    tags: [
      {
        id: 1,
        name: 'Saleshandy',
      },
    ],
    lastContactedAt: '16 Sept, 2024 at 12:11 AM',
  },
];
