import React, { useEffect } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { accessibleOnClick } from '../../../../../../../shared/utils/accessible-on-click';
import { BulkSelectedEmailAccounts } from '../../../types';
import { constants } from '../../../../../../../shared/enums/constants';
import {
  getFormattedEmailAccountsCount,
  getSelectedEmailAccountsCount,
} from '../utils/helper';
import Alert from '../../../../../../../shared/design-system/ui/alert';

type IProps = {
  selectedEmailAccounts: any[];
  bulkSelectedEmailAccounts: BulkSelectedEmailAccounts;
  totalEmailAccounts: number;
  handleSelectAllEmailAccount: VoidFunction;
  handleClearSelection: VoidFunction;
};

const EmailSelectedRowInfoBanner: React.FC<IProps> = ({
  selectedEmailAccounts,
  bulkSelectedEmailAccounts,
  totalEmailAccounts,
  handleSelectAllEmailAccount,
  handleClearSelection,
}) => {
  const { t } = useTranslation();

  const selectedEmailAccountsCount = getSelectedEmailAccountsCount(
    selectedEmailAccounts,
    bulkSelectedEmailAccounts,
    totalEmailAccounts,
  );

  useEffect(() => {
    if (selectedEmailAccountsCount === 0) {
      handleClearSelection();
    }
  }, [selectedEmailAccountsCount]);

  const divClasses = classnames([
    'email-selected-row-info alert-width',
    {
      'email-selected-row-info-waring':
        selectedEmailAccounts.length > 0 ||
        bulkSelectedEmailAccounts?.isAllEmailAccountsSelected,
    },
    {
      'email-selected-row-info-danger':
        selectedEmailAccounts.length === 0 &&
        !bulkSelectedEmailAccounts?.isAllEmailAccountsSelected,
    },
  ]);

  const spanClasses = classnames([
    'semibold-1',
    {
      'popover-arrow-color-txt':
        selectedEmailAccounts.length > 0 ||
        bulkSelectedEmailAccounts?.isAllEmailAccountsSelected,
    },
    {
      'red-txt-12':
        selectedEmailAccounts.length === 0 &&
        !bulkSelectedEmailAccounts?.isAllEmailAccountsSelected,
    },
  ]);

  const renderSelectedEmailAccountDetails = () => (
    <div className="d-flex">
      {selectedEmailAccountsCount ===
        constants.BULK_EDIT_EMAIL_ACCOUNT_LIMIT && (
        <span className={`${spanClasses} mr-1`}>First</span>
      )}

      <span className={`${spanClasses} mr-2`}>
        {getFormattedEmailAccountsCount(selectedEmailAccountsCount)}
      </span>

      {!bulkSelectedEmailAccounts?.isAllEmailAccountsSelected &&
        totalEmailAccounts !== selectedEmailAccounts?.length && (
          <>
            <span
              role="button"
              className="semibold-1 blue-txt-11"
              {...accessibleOnClick(handleSelectAllEmailAccount)}
            >
              Select all {totalEmailAccounts} Email Account(s)
            </span>
            <span className="divider-vertical" />
          </>
        )}

      <span
        role="button"
        className="semibold-1 blue-txt-11"
        {...accessibleOnClick(handleClearSelection)}
      >
        Clear selection
      </span>

      {totalEmailAccounts > constants.BULK_EDIT_EMAIL_ACCOUNT_LIMIT && (
        <span className="regular-1 red-txt-12 ml-2">
          (You can select max 25,000 email accounts.)
        </span>
      )}
    </div>
  );

  if (totalEmailAccounts > 0) {
    return (
      <Alert
        className={divClasses}
        variant="alert"
        description={
          selectedEmailAccounts.length > 0 ||
          bulkSelectedEmailAccounts?.isAllEmailAccountsSelected ? (
            renderSelectedEmailAccountDetails()
          ) : (
            <span className={spanClasses}>
              {t('messages.prospect_not_selected_warning')}
            </span>
          )
        }
      />
    );
  }

  return <></>;
};

export default EmailSelectedRowInfoBanner;
