import { EmailFilters } from '../../components/sequence/components/sequence-single-content/components/sequence-single-emails/components/email-filter-modal/types';

// Filters that can be applied to Prospects.
export enum Filters {
  Tags = 'tags',
  Owners = 'owners',
  Status = 'status',
  Sequences = 'sequences',
  VerificationStatus = 'verification status',
  CreatedDate = 'created date',
  Outcomes = 'outcomes',
  CreationSource = 'creation source',
  TaskPriority = 'task priority',
  EmailAccount = 'email account',
  ClientAssociated = 'client associated',
}

export type ProspectsFilters = Record<Filters, any[]>;

export type FilterProperties = {
  /**
   * Name of the key to extract the the text to display on UI from the filter object.
   */
  displayNameKey: string;
  /**
   * Name of the key to extract the value of the filter. This value will be send to API calls.
   */
  valueKey: string;
  /**
   * To separate the filters on UI in Filter Tab.
   */
  separator: string;
  /**
   * To separate the filters on UI in Filter Tab.
   */
  displayLabel?: string;
};

export type ProspectFilterResetArg = Filters | 'all';

export type SequenceEmailsFilterResetArg = EmailFilters | 'all';
