import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../shared/enums/request-status';
import { RequestState } from '../../shared/types/request-state';
import {
  getTasksRequest,
  getTaskStatusCountRequest,
  updateTasksRequest,
  getTaskerDataRequest,
  updateTaskNoteRequest,
} from './extra-actions';
import {
  ProspectFilterResetArg,
  ProspectsFilters,
} from '../../shared/types/prospects-filters';
import {
  getTasksFiltersFromLocalStore,
  removeTasksFiltersFromLocalStore,
  setTasksFiltersInLocalStore,
} from './components/tasks-content/utils/helper';

export const tasksFiltersInitialState: ProspectsFilters = {
  tags: [],
  owners: [],
  status: [],
  outcomes: [],
  sequences: [],
  'verification status': [],
  'task priority': [],
  'created date': [],
  'creation source': [],
  'email account': [],
  'client associated': [],
};

interface State {
  getTasksRequest: RequestState;
  getTaskStatusCountRequest: RequestState;
  updateTasksRequest: RequestState;
  getTaskerDataRequest: RequestState;
  updateTaskNoteRequest: RequestState;
  tasks: any[];
  taskStatusCount: any[];
  tasksCount: number;
  tasksFilters: ProspectsFilters;
  selectedTasks: any[];
  taskerData: any[];
}

const initialState: State = {
  getTasksRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getTaskStatusCountRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateTasksRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getTaskerDataRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateTaskNoteRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  tasks: [],
  taskStatusCount: null,
  tasksCount: null,
  tasksFilters: getTasksFiltersFromLocalStore() || tasksFiltersInitialState,
  selectedTasks: [],
  taskerData: [],
};

const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    checkSingle: (state, action) => {
      if (action.payload.status === true) {
        state.selectedTasks = state.selectedTasks.concat([action.payload.row]);
      } else {
        state.selectedTasks = state.selectedTasks.filter(
          ({ id }) => id !== action.payload.row.id,
        );
      }
    },
    checkAll: (state, action) => {
      if (action.payload.status === true) {
        state.selectedTasks = state.selectedTasks.concat(action.payload.rows);
      } else {
        state.selectedTasks = state.selectedTasks.reduce((acc, item) => {
          let isPresent = false;
          action.payload.rows.forEach(({ id }) => {
            if (id === item.id) {
              isPresent = true;
            }
          });
          if (isPresent) {
            return acc;
          }
          return [...acc, item];
        }, []);
      }
    },
    resetSelectedTasks: (state) => {
      state.selectedTasks = initialState.selectedTasks;
    },
    resetTasksState: (state) => {
      state.updateTasksRequest = initialState.updateTasksRequest;
    },
    resetTaskerState: (state) => {
      state.getTaskerDataRequest = initialState.getTaskerDataRequest;
    },
    setTasksFilters: (state, action) => {
      state.tasksFilters = { ...action.payload };
      setTasksFiltersInLocalStore(action.payload);
    },
    resetTasksFilters: (state, action) => {
      const resetOff: ProspectFilterResetArg = action.payload;
      if (resetOff === 'all') {
        state.tasksFilters = tasksFiltersInitialState;

        removeTasksFiltersFromLocalStore();
      } else {
        state.tasksFilters[resetOff] = [];
        setTasksFiltersInLocalStore(state.tasksFilters);
      }
    },
    removeTaskFromTaskerData: (state, action) => {
      state.taskerData = state.taskerData.filter(
        (task) => task.id !== action.payload.taskId,
      );
    },
  },
  extraReducers: (builder) => {
    // Get Tasks Status Count
    builder.addCase(getTaskStatusCountRequest.pending, (state) => {
      state.getTaskStatusCountRequest.status = RequestStatus.Pending;
      state.getTaskStatusCountRequest.error = null;
    });
    builder.addCase(getTaskStatusCountRequest.fulfilled, (state, action) => {
      state.getTaskStatusCountRequest.status = RequestStatus.Succeeded;
      state.getTaskStatusCountRequest.message = action.payload.message;
      const [payload] = action.payload.payload;
      state.taskStatusCount = payload;
    });
    builder.addCase(getTaskStatusCountRequest.rejected, (state, action) => {
      state.getTaskStatusCountRequest.status = RequestStatus.Failed;
      state.getTaskStatusCountRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Tasks
    builder.addCase(getTasksRequest.pending, (state) => {
      state.getTasksRequest.status = RequestStatus.Pending;
      state.getTasksRequest.error = null;
    });
    builder.addCase(getTasksRequest.fulfilled, (state, action) => {
      state.getTasksRequest.status = RequestStatus.Succeeded;
      state.getTasksRequest.message = action.payload.message;
      state.tasks = action.payload.payload?.data;
      state.tasksCount = action.payload.payload?.count;
    });
    builder.addCase(getTasksRequest.rejected, (state, action) => {
      state.getTasksRequest.status = RequestStatus.Failed;
      state.getTasksRequest.error = !action.payload.isHandled && action.payload;
    });

    // Update Tasks
    builder.addCase(updateTasksRequest.pending, (state) => {
      state.updateTasksRequest.status = RequestStatus.Pending;
      state.updateTasksRequest.error = null;
    });
    builder.addCase(updateTasksRequest.fulfilled, (state, action) => {
      state.updateTasksRequest.status = RequestStatus.Succeeded;
      state.updateTasksRequest.message = action.payload.message;
    });
    builder.addCase(updateTasksRequest.rejected, (state, action) => {
      state.updateTasksRequest.status = RequestStatus.Failed;
      state.updateTasksRequest.message = action.payload.message;
      state.updateTasksRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Tasker Data
    builder.addCase(getTaskerDataRequest.pending, (state) => {
      state.getTaskerDataRequest.status = RequestStatus.Pending;
      state.getTaskerDataRequest.error = null;
    });
    builder.addCase(getTaskerDataRequest.fulfilled, (state, action) => {
      state.getTaskerDataRequest.status = RequestStatus.Succeeded;
      state.getTaskerDataRequest.message = action.payload.message;
      state.taskerData = action.payload.payload;
    });
    builder.addCase(getTaskerDataRequest.rejected, (state, action) => {
      state.getTaskerDataRequest.status = RequestStatus.Failed;
      state.getTaskerDataRequest.message = action.payload.message;
      state.getTaskerDataRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Update Task Note
    builder.addCase(updateTaskNoteRequest.pending, (state) => {
      state.updateTaskNoteRequest.status = RequestStatus.Pending;
      state.updateTaskNoteRequest.error = null;
    });
    builder.addCase(updateTaskNoteRequest.fulfilled, (state, action) => {
      state.updateTaskNoteRequest.status = RequestStatus.Succeeded;
      state.updateTaskNoteRequest.message = action.payload.message;
    });
    builder.addCase(updateTaskNoteRequest.rejected, (state, action) => {
      state.updateTaskNoteRequest.status = RequestStatus.Failed;
      state.updateTaskNoteRequest.message = action.payload.message;
      state.updateTaskNoteRequest.error =
        !action.payload.isHandled && action.payload;
    });
  },
});

export const {
  checkSingle,
  checkAll,
  resetTasksState,
  setTasksFilters,
  resetTasksFilters,
  resetSelectedTasks,
  removeTaskFromTaskerData,
  resetTaskerState,
} = tasksSlice.actions;
export default tasksSlice.reducer;
