import React from 'react';
import { Modal as ReactBootstrapModal } from 'react-bootstrap';
import Button from '../button';

const { Header, Title, Body, Footer } = ReactBootstrapModal;

type IProps = {
  show: boolean;
  className?: string;
  title: React.ReactNode;
  onHide: VoidFunction;

  primaryBtnLabel?: string;
  secondaryBtnLabel?: string;

  primaryBtnDisabled?: boolean;
  secondaryBtnDisabled?: boolean;

  primaryBtnLoading?: boolean;
  secondaryBtnLoading?: boolean;

  primaryBtnClassName?: string;
  secondaryBtnClassName?: string;

  primaryBtnClickedHandler: VoidFunction;
  secondaryBtnClickedHandler: VoidFunction;

  footer?: React.ReactNode;
};

const Modal: React.FC<IProps> = ({
  show,
  className = '',
  title = '',
  onHide,
  primaryBtnLabel = 'Save',
  secondaryBtnLabel = 'Cancel',
  primaryBtnDisabled = false,
  secondaryBtnDisabled = false,
  primaryBtnLoading = false,
  secondaryBtnLoading = false,
  primaryBtnClassName = '',
  secondaryBtnClassName = 'cancel-btn',
  primaryBtnClickedHandler,
  secondaryBtnClickedHandler,
  children,
  footer,
}) => (
  <ReactBootstrapModal
    show={show}
    onHide={onHide}
    className={`shd-modal ${className}`}
    centered
    backdrop="static"
    aria-labelledby="contained-modal-title-vcenter"
    enforceFocus={false}
  >
    <Header className="shd-modal--header">
      <Title className="shd-modal--title">{title}</Title>
    </Header>

    <Body className="shd-modal--body">{children}</Body>

    <div className="shd-modal--divider" />

    <Footer className={`shd-modal--footer ${footer ? 'with-content' : ''}`}>
      {footer && <div className="shd-modal--footer__left">{footer}</div>}

      <div className="shd-modal--footer__right">
        <Button
          variant="secondary"
          onClick={secondaryBtnClickedHandler}
          disabled={secondaryBtnDisabled}
          isLoading={secondaryBtnLoading}
          loadingText={`${secondaryBtnLabel}...`}
          className={secondaryBtnClassName}
        >
          {secondaryBtnLabel}
        </Button>
        <Button
          variant="primary"
          onClick={primaryBtnClickedHandler}
          disabled={primaryBtnDisabled}
          isLoading={primaryBtnLoading}
          loadingText={`${primaryBtnLabel}...`}
          className={primaryBtnClassName}
        >
          {primaryBtnLabel}
        </Button>
      </div>
    </Footer>
  </ReactBootstrapModal>
);

export default Modal;
